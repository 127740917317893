
import { useState, useRef } from 'react';

export const useProgress = () => {
    const progressRef = useRef([]);
    const [inProgress, setInProgress] = useState('');

    const handleChangeProgress = (val) => {
        const { message, key } = val;

        if (message) {
            const activeItem = progressRef?.current?.find(item => item.key === key);
            if (!activeItem) {
                progressRef?.current.push(val);
                setInProgress(val.message);
            }
        } else {
            const index = progressRef?.current.findIndex(item => item.key === key);

            if (index !== -1) {
                progressRef?.current.splice(index, 1);
                setInProgress(progressRef?.current?.[0]?.message || '');
            }
        }
    };

    return { inProgress, handleChangeProgress };
};