/* global YT */
import './scss/index.scss';
import React, { useEffect, useState, } from 'react';
import { useIntl } from 'react-intl';
import { VideoPlayer, VideoAnyPlayer, VimeoPlayer, VideoGeneratorButton } from './';
import { useReducedCaptions, useGetSummaryLongForm } from '../../ai/hook';
import { useEditTask } from '../../task/hooks/useEditTask';
import { useGetCaptions, ChangeVideoContainer } from '../../common';
import { YouTubePublicTab } from '../../common/ChangeVideo.container/';

const TEXT_URL = 'https://text.unschooler.me/?url=';
const MAX_INPUT_LENGTH = 30000;

export const VideoGenerator = ({
    task,
    user,
    generateFromVideo,
    handleRegenerate,
    setGenerateFromVideo,
    setInProgress,
    workspace,
    userIsAllowGenerateContent,
    handleEditVideoTask,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const { getSummaryLongForm } = useGetSummaryLongForm();
    const getReducedCaptions = useReducedCaptions();
    const { editTask } = useEditTask();
    const { captions, captionsLoading, getCaptions } = useGetCaptions();

    const [videoSrc, setVideoSrc] = useState();
    const [coverType, setCoverType] = useState(task?.cover?.type);
    const [summary, setSummary] = useState(task?.cover?.summary);
    const [showSummary, setShowSummary] = useState(false);

    const isVideo = coverType === 'VIDEO' && !!videoSrc;
    const creatorOrAdmin = user?.id === task?.creatorId || (user && user?.role?.id === 1);
    const showPublicVideo = !task?.cover && task?.isAutogenerated
    const showAIVideo = !showPublicVideo && (!task?.cover?.source?.length || !task?.cover)
    const handlePrepareToEditVideoTask = (coverData) => {
        handleEditVideoTask({ cover: coverData })
    }

    const handleGenerateTutorial = async (subtitles) => {
        setInProgress({
            message: formatMessage({ id: 'progress_subtitle_generation_message' }),
            key: 'editTaskLoading'
        })

        if (!!subtitles) {
            const summary = await handleGenerateSummary(subtitles);
            if (summary) {
                handleRegenerate(summary);
                task?.id && saveSummaryToTask(subtitles, summary)
            }
        }
        setInProgress({
            key: 'editTaskLoading'
        })
    }
    const saveSummaryToTask = async (subtitles, summary) => {
        const params = {
            variables: {
                taskId: task?.id,
                taskData: {
                    cover: {
                        id: task?.cover?.id,
                        raw: subtitles?.substring(0, 10000),
                        source: task?.cover?.source,
                        type: coverType,
                        summary: summary,
                    },
                    source: summary,
                    ...(task?.tags && {
                        tagIds: task?.tags?.map((tag) => tag?.id),
                    })
                },
            }
        };
        await editTask(params);
    }
    const handleGenerateSummary = async (subtitles) => {
        setInProgress({
            message: formatMessage({ id: 'progress_subtitle_generation_message' }),
            key: 'editTaskLoading'
        })

        if (!!subtitles) {
            const title = task?.title?.substring(0, 70);

            if (subtitles.length >= MAX_INPUT_LENGTH) {
                //make it shorter
                const summary = await getReducedCaptions(subtitles, title);
                if (task?.id) {
                    task?.id && saveSummaryToTask(subtitles, summary)
                }
                setSummary(summary);
                setInProgress({
                    key: 'editTaskLoading'
                })
                return summary;
            } else {
                const summary = await getSummaryLongForm(subtitles, title);
                if (task?.id) {
                    task?.id && saveSummaryToTask(subtitles, summary)
                }
                setSummary(summary);
                setInProgress({ key: 'editTaskLoading' })
                return summary;
            }
        }
    };
    const onGenerateSubtitles = async () => {
        setInProgress({
            message: formatMessage({ id: 'progress_subtitle_generation_message' }),
            key: 'generateSubtitles'
        })

        try {
            const myHeaders = new Headers();
            myHeaders.append("Origin", "https://text.unschooler.me");

            const resp = await fetch(`${TEXT_URL}${videoSrc}`, {
                method: 'GET',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
            });
            const jsonData = await resp.json();
            if (jsonData?.text) {
                handleGenerateTutorial(jsonData?.text)
            }
        } catch (error) {
            console.error(error)
        }
        setInProgress({
            key: 'generateSubtitles'
        })
    }
    const handleSummaryClick = async () => {
        if (!summary) {
            handleGenerateSummary(captions);
        }
        setShowSummary(prev => !prev);
    }
    useEffect(() => {
        if (task?.id && generateFromVideo
        ) {
            setGenerateFromVideo(true);
            (async () => {
                let text = task?.cover?.raw || task?.cover?.summary || captions || await getCaptions(coverType, videoSrc, task?.cover) || '';
                if (text) {
                    handleGenerateTutorial(text);
                } else {
                    coverType === "YOUTUBE" ? onGenerateSubtitles() : handleRegenerate();
                }
                setGenerateFromVideo(false);
            })();
        }
    }, [task?.cover?.source, generateFromVideo])

    useEffect(() => {
        if (videoSrc && !captionsLoading && !captions && task?.cover?.type === 'YOUTUBE') {
            (async () => {
                await getCaptions(coverType, videoSrc, task?.cover);
            })();
        }
        setVideoSrc(task?.cover?.source);
        setCoverType(task?.cover?.type);

    }, [task?.id, task?.cover?.source]);


    return (
        <>
            {userIsAllowGenerateContent && creatorOrAdmin && (
                    <ChangeVideoContainer
                        task={task}
                        user={user}
                        workspace={workspace}
                        userIsAllowGenerateContent={userIsAllowGenerateContent}
                        setInProgress={setInProgress}
                        handleEditVideoTask={handleEditVideoTask}
                        {...other}
                    />
            )}
            {coverType === "YOUTUBE" && (
                <VideoPlayer
                    {...other}
                    timestampsList={task?.cover?.timestamps}
                    task={task}
                    user={user}
                    videoSrc={videoSrc}
                    showCaptions={true}
                    handleGenerateSummary={handleGenerateSummary}
                    captions={captions}
                    summary={summary}
                    handleSummaryClick={handleSummaryClick}
                    showSummary={showSummary}
                    {...other}
                />
            )}
            {coverType === "VIMEO" && (
                <VimeoPlayer
                    {...other}
                    videoSrc={videoSrc}
                />
            )}
            {(coverType === "VIDEO" && !!task?.cover?.source?.length) &&
                <>
                    {isVideo && (
                        <video width="100%" controls>
                            <source src={videoSrc} />
                        </video>
                    )}
                    <VideoAnyPlayer
                        {...other}
                        timestampsList={task?.cover?.timestamps}
                        task={task}
                        user={user}
                        showCaptions={true}
                        video={{ source: '' }}
                        captions={captions}
                    />
                </>
            }
            {showPublicVideo &&
                <YouTubePublicTab
                    setInProgress={setInProgress}
                    setCover={handlePrepareToEditVideoTask}
                    task={task}
                    user={user}
                    workspace={workspace}
                    userIsAllowGenerateContent={userIsAllowGenerateContent}
                    {...other}
                />
            }
            {showAIVideo &&
                <VideoGeneratorButton
                    task={task}
                    coverFromTask={task?.cover}
                    user={user}
                    setInProgress={setInProgress}
                    workspace={workspace}
                    userIsAllowGenerateContent={userIsAllowGenerateContent}
                    handleEditVideoTask={handleEditVideoTask}
                    {...other}
                />
            }
        </>
    );
};
