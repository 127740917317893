
import './scss/index.scss';
import React, { useState } from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import { useGetVideoCaptions } from '../../youtube';
import { YouTubeInput } from '../../common';

export const AddYouTubeLink = ({
    onAddSource,
    index,
    setInProgress,
    inProgress,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const [showModal, setShowModal] = useState(true);
    const [urlValue, setUrlValue] = useState('');
    const [urlName, setUrlName] = useState('');

    const onVideoDataChange = ({ urlName, sourceText }) => {
        setUrlValue(sourceText);
        setUrlName(urlName);
    };
    const onSave = () => {
        onAddSource(object, index);
        setShowModal(false)
    }

    let object = { key: "youtube", type: 'YOUTUBE', source: urlName || '', title: `My YouTube video`, raw: urlValue || '', icon: "video" }

    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: 'form_video_placeholder' })}
                onCancel={() => onSave()}
                onOk={() => onSave()}
                okButtonProps={{ disabled: !urlValue }}
                confirmLoading={inProgress}
            >
                <YouTubeInput
                    onVideoDataChange={onVideoDataChange}
                    inProgress={inProgress}
                    setInProgress={setInProgress}
                    {...other}
                />
            </Modal>
        </>
    );
};
