import React, { useEffect, useState, useRef, useMemo } from 'react';
import { cloneDeep } from "lodash";
import { message, Modal } from 'antd';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useTaskDripLogic } from './hooks/useTaskDripLogic';
import {
    useGetResult,
    useUpdateResult,
    useGetResults,
    useGetResultsByUserid
} from "../result";
import { TaskArticleComponent } from "./TaskArticle.component";
import { TaskCourseArticleComponent } from "./TaskCourseArticle.component";
import { stepParseJson, getModelFromAudioId, useTrackClick, useIsAllow, VoiceSelector, useConditionalVoiceMode } from '../common';
import { useEditTask } from "./hooks/useEditTask";
import { useProgress } from '../common/hooks/useProgress'; 

import {
    useGetResultAi,
    useGetSummaryLongForm,
    useReducedCaptions,
    useGetAiVideo,
} from "../ai";
import { useAiChatResponse } from "../ai/graphQL/useAiChatResponse";
import { useIsPermit } from '../roles/hook/useCheckPermission';
let actualQuestions;
let NEW_CONTENT = '';
let isQuestionProgress = false;
let descriptionInProgress = false;

export const TaskArticleContainer = (props) => {
    const { task, user, updateTask, event, localePrefix, workspace, isFirst, ...other } = props;
    const { formatMessage } = useIntl();
    const { id, tags, questions, title, creatorId } = task || {};
    const { updateResult } = useUpdateResult();
    const getReducedCaptions = useReducedCaptions();
    const MAX = localePrefix ? 30000 : 50000;
    actualQuestions = questions;

    const questionStarted = useRef(false);
    const { resultsByUserid, getResultsByUserid } = useGetResultsByUserid();
    const trackClick = useTrackClick();
    const history = useHistory();
    const { search } = useLocation();
    let values = queryString.parse(search);
    const { result, getResult } = useGetResult();
    const { results, getResults } = useGetResults();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { inProgress, handleChangeProgress } = useProgress();

    const [voiceMode, setVoiceMode] = useState();
    const curriculum = useMemo(() => {
        const titles = event?.tasks?.map(t => t?.title?.substring(0, 55)) || [];
        return titles.join(', ');
    }, [event?.tasks]);

    const [highlightedRequest, setHighlightedRequest] = useState();
    const [highlightedNode, setHighlightedNode] = useState();
    const [taskContent, setTaskContent] = useState(task?.description || '');
    const [resultState, setResultState] = useState(result);
    const [inProgressNextStep, setInProgressNextStep] = useState();
    const [inProgressHighlight, setInProgressHighlight] = useState();
    const [taskNotLogged, setTaskNotLogged] = useState(task);

    // const [inProgress, setInProgress] = useState();
    // const [autoQuestionsLoad, setAutoQuestionsLoad] = useState(false);
    // const [videoSet, setVideoSet] = useState();
    // const [videoId, setVideoId] = useState(task?.cover?.source);
    // const [videoSrc, setVideoSrc] = useState(task?.cover?.type !== "IMAGE" && task?.cover?.source);

    const [stepId, setStepId] = useState(values?.step);
    const [sourceFromVideo, setSourceFromVideo] = useState(false);

    const { editTask } = useEditTask();
    const { getResultAi } = useGetResultAi();
    const { getChatResponse } = useAiChatResponse();
    const { getSummaryLongForm } = useGetSummaryLongForm();
    const { getGenerateVideoAndIntro } = useGetAiVideo(task, handleChangeProgress);

    const { checkPermit } = useIsPermit()
    const editOthersCourses = checkPermit(workspace, 'editOthersCourses')
    const useIsAllowed = useIsAllow();
    const isPaid = useIsAllowed("PREMIUM");
    const userIsAllowGenerateContent = ((isFirst || isPaid || checkPermit(workspace, 'createCourses')) && user?.id === creatorId) || editOthersCourses || user?.role?.id === 1;

    let goal = event?.title?.substring(0, 150);
    const { taskResult, tagsRenew, progressCounter } = useTaskDripLogic(event, task, resultState, resultsByUserid, userIsAllowGenerateContent, [resultState]);
    const isJoinRequired = useMemo(() => {
        let result = '';
        const currentTaskWithHidden = taskResult.find(t => t?.id === task?.id);
        const currentQuestion = stepId ? currentTaskWithHidden?.questions?.find(q => q.id === stepId) : null;
        if (user?.id === creatorId || user?.role?.id === 1) {
            return result;
        }

        //check if joined
        if (event && !event?.isDraft) {
            result = !event?.isSubscribe ? 'join' : '';
        }

        if (event && !event?.isDraft) {
            result = !task?.isAvailable && !userIsAllowGenerateContent ? 'join' : '';
        }
        //check if drip feeding
        if (task?.isAvailable && currentTaskWithHidden?.isHidden) {
            result = 'drip';
        }
        //check if it is question is hidden and apply result = 'drip '
        if (task?.isAvailable && currentQuestion?.isHidden) {
            result = 'drip';
        }

        return result;

    }, [user, creatorId, stepId, resultState?.answers?.length, event, task]);

    // result
    const onUpdateResultDescription = async (newPartOfDescription) => {
        trackClick(
            'click-resultform',
            task.id,
            `https://unschooler.me${localePrefix}/tutorials/${task.id}`
        );

        let normalisedAnswers = [];
        result?.answers?.forEach((ans) => {
            normalisedAnswers.push({
                answer: ans.answer,
                questionId: ans.questionId,
                optionIds: ans.optionIds
                    ? ans.optionIds
                    : ans.options?.map((option) => option.id),
            });
        });

        if (user?.id) {
            let newResult = {
                answers: normalisedAnswers || [],
                taskId: task.id,
                description: (result?.description ? result?.description : '') + (newPartOfDescription ? newPartOfDescription : '') || '',
            }

            const { id, workspaceId } = event || {};

            await updateResult({
                variables: {
                    resultData: {
                        ...newResult,
                    },
                },
            }, { id, workspaceId });

        }
    };
    const updateResults = async () => {
        getResults({
            variables: { filter: { taskId: task?.id } },
            fetchPolicy: "network-only",
        });

        getResult({
            variables: { taskId: task?.id },
            fetchPolicy: "network-only",
        });
    };
    const handleMouseUp = () => {
        const string = window?.getSelection()?.toString();
        setHighlightedNode(window?.getSelection()?.extentNode?.wholeText);
        setHighlightedRequest(
            window?.getSelection()?.type === "Range" ? string : null
        );
    };
    const getPrompt = (prompt) => {
        if (prompt) {
            return `${prompt} Put examples in code block and terms with emoji in bold `;
        }
    }
    const onRegenerate = async (summary) => {
        let summaryNumber = summary?.length || 0;
        if (summary?.length > MAX) {
            // source is exist and it is too big
            handleChangeProgress({
                message: formatMessage({ id: 'progress_subtitle_generation_message' }),
                key: 'summary'
            })
            let string = '';
            if (task.summary) {
                if (task.summary.length > MAX) {
                    // summary is exist and it is too big,summarise it
                    string = await getSummaryLongForm(task.summary, title, 'summariseLongForm', 5000);
                } else {
                    // summary is exist and it is short, take it
                    string = task.summary;
                }
                (!NEW_CONTENT && !task.description) && await handleDescriptionStart(string);
                (!isQuestionProgress) && await handleCreateQuestion(string, true);
                handleChangeProgress({ key: 'summary' })

            } else {
                // summary is not exist, generate summary
                const shortenedSummary = await getReducedCaptions(summary, title, 30000);

                const params = {
                    variables: {
                        taskId: task?.id,
                        taskData: {
                            source: summary,
                            summary: shortenedSummary,
                            ...(task?.tags && {
                                tagIds: task?.tags?.map((tag) => tag?.id),
                            })
                        },
                    }
                };
                editTask(params);
                (!NEW_CONTENT && !task.description) && await handleDescriptionStart(shortenedSummary);
                !isQuestionProgress && await handleCreateQuestion(shortenedSummary, true);
                handleChangeProgress({ key: 'summary' })
            }
            handleChangeProgress({
                key: 'summary'
            })

        } else {
            // source is not exist
            // if (!task?.isAutogenerated && videoSrc && summaryNumber < 500) {
            if (!task?.isAutogenerated && (task?.cover?.type !== "IMAGE" && task?.cover?.source) && summaryNumber < 500) {
                // but this is my video content
                setSourceFromVideo(true);
                descriptionInProgress = true
            } else {
                //  this is not my content
                (!NEW_CONTENT && !task.description) && await handleDescriptionStart(summary);
                !isQuestionProgress && await handleCreateQuestion(summary, true);
            }
            handleChangeProgress({ key: 'summary' })
        }
    }
    const handleGenerateIntroVideo = async () => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_description_message' }),
            key: 'descriptionStart'
        })

        const resp = await getGenerateVideoAndIntro(task, event, voiceMode);
        const { coverData, markdown, error } = resp || {};
        if (error) {
            errorMessage(error)
        }

        await handleEditVideoTask(coverData, markdown);
        descriptionInProgress = false;
        handleChangeProgress({ key: 'descriptionStart' });
    }
    const handleDescriptionStart = async (summary) => {
        let stepTitle = task?.title?.replace(/#/g, '')?.replace('\u0002', "")?.replace('\\', "");
        if (task?.cover?.source || task?.isAutogenerated) {
            const settings = {
                args: {
                    ...(goal && { goal: goal }),
                    stepTitle,
                    ...(summary && { source: summary }),
                    ...(curriculum && { curriculum: curriculum })
                },
                type: 'nextStepOwnContent'
            };

            try {
                handleChangeProgress({
                    message: formatMessage({ id: 'progress_description_message' }),
                    key: 'descriptionStart'
                })

                const resp = await getChatResponse({ variables: { input: settings, } });
                const responseJSON = JSON.parse(resp?.data?.requestChatApi);
                const respData = responseJSON?.choices?.[0]?.message?.content;
                await handleEditTask(respData);
                descriptionInProgress = false;
                handleChangeProgress({ key: 'descriptionStart' });
            } catch (error) {
                errorMessage('AI did not answered, reload the page and try again');
            }

        } else {
            await handleGenerateIntroVideo(summary, stepTitle)
        }
    }
    const handlePlanGenerate = async (settings) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_plan_generate_message' }),
            key: 'planGenerate'
        })
        const resp = await getChatResponse({
            variables: {
                input: settings,
            },
        });
        const responseJSON = resp?.data
            ? JSON.parse(resp?.data?.requestChatApi)
            : null;
        handleChangeProgress({ key: 'planGenerate' })
        if (responseJSON?.choices?.[0]) {
            return responseJSON?.choices?.[0]?.message?.content?.trim();
        }
    };
    const handleCreateQuestion = async (prompt, noRedirect) => {
        if (!task?.questions?.length) {
            handleChangeProgress({
                message: formatMessage({ id: 'progress_create_question_message' }),
                key: 'createQuestion'
            })
            isQuestionProgress = true;
            const settings = {
                args: {
                    title: task?.title + '\n' + prompt?.substring(0, 30000),
                    curriculum: curriculum,
                    goal: event?.title,
                },
                type: "tutorialProjectStepsLongForm",
            };

            let stepTitle = await handlePlanGenerate(settings);

            const parsedSteps = stepParseJson(stepTitle);
            if (parsedSteps?.steps) {
                let convertedQuestions = [];
                let allSteps = parsedSteps.steps;
                for (let step = 0; step < parsedSteps.steps?.length; step++) {
                    let questionTitle = `## ${allSteps[step]}.\n\n${getPrompt(prompt?.substring(0, 2000)) || ''}`;
                    let newQuestion = {
                        type: "TEXT",
                        question: '',
                        title: questionTitle,
                        order: step,
                    };

                    convertedQuestions.push(newQuestion);
                }

                actualQuestions = convertedQuestions;
                const { data } = await handleEditQuestions(convertedQuestions);

                handleChangeProgress({ key: 'createQuestion' })
                isQuestionProgress = false;
                if (!noRedirect) {
                    const nextStepId = data.editTask?.questions?.[0]?.id;
                    const searchParams = new URLSearchParams(search);
                    searchParams.set('step', nextStepId.toString());
                    history.push({ search: searchParams.toString() });
                }
            } else {
                handleChangeProgress({ key: 'createQuestion' })
            }
        } else {
            let steps = []
            task?.questions?.forEach((question) => { steps.push(question?.title); });
            let settings = {
                args: {
                    steps,
                    title: task?.title,
                    source: prompt,
                    curriculum: curriculum,
                },
                type: "tutorialProjectStepsOutlines",
            };
            const resp = await getChatResponse({
                variables: {
                    input: settings,
                },
            });
            const responseJSON = resp?.data
                ? JSON.parse(resp?.data?.requestChatApi)
                : null;
            if (responseJSON?.choices?.[0]) {
                let responseSteps = JSON.parse(responseJSON?.choices?.[0]?.message?.content);
                if (responseSteps?.steps) {
                    const updatedQuestions = task?.questions?.map((question, index) => {
                        if (responseSteps?.steps[index]) {
                            return {
                                ...question,
                                title: `${responseSteps.steps[index]?.title}\n${responseSteps.steps[index]?.outlines}`
                            };
                        }
                        return question;
                    });
                    actualQuestions = updatedQuestions;
                    await handleEditQuestions(updatedQuestions);
                }
            }
        }
    };

 
    const handleCheckResultAi = async (questionId, userAnswer) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_check_result_message' }),
            key: 'checkResultAi'
        })

        const question = task?.questions?.find((q) => q.id === questionId);
        const response = await getResultAi(question?.answer, userAnswer, formatMessage);
        if (response) {
            handleChangeProgress({
                key: 'checkResultAi'
            })
            return response;
        }
    };

    //TODO: move to the handleEditTaskCommon
    const handleEditTask = async (description, aiAnswer) => {
        setTaskContent(description);

        let tagIdsArray = [];
        task?.tags?.forEach((tag) => tagIdsArray.push(tag.id));

        if (userIsAllowGenerateContent) {
            try {
                await editTask({
                    variables: {
                        taskId: task?.id,
                        taskData: {
                            description: description,
                            tagIds: tagIdsArray,
                        },
                        isOwner: editOthersCourses,
                    },
                });
                successMessage('description');

            } catch (error) {
                errorMessage('Error editing task, try to reload the page');
            }
        } else if (user?.id) {
            await onUpdateResultDescription(aiAnswer)
        }
        setTaskNotLogged((prev) => {
            let taskLocalNew = { ...prev };
            taskLocalNew.description = description;
            return taskLocalNew;
        });
        if (updateTask) {
            await updateTask({ fetchPolicy: "network-only" });
        }
    };
    const handleEditVideoTask = async (coverData, description) => {
        let tagIdsArray = [];
        task?.tags?.forEach((tag) => tagIdsArray.push(tag.id));

        await editTask({
            variables: {
                taskId: task?.id,
                taskData: {
                    tagIds: tagIdsArray,
                    description: description,
                    ...coverData,
                },
                isOwner: editOthersCourses,
            },
        });
        successMessage('video');

        if (updateTask) {
            await updateTask({ fetchPolicy: "network-only" });
        }
    };
    const handleEditQuestions = async (convertedQuestions) => {
        let tagIdsArray = [];
        tags?.forEach((tag) => tagIdsArray.push(tag.id));

        const data = await editTask({
            variables: {
                taskId: task?.id,
                taskData: {
                    questions: convertedQuestions,
                    tagIds: tagIdsArray,
                },
                isOwner: editOthersCourses,
            },
        });

        if (updateTask) {
            await updateTask({ fetchPolicy: "network-only" });
        }
        return data;
    };
    const handleEditQuestion = async (newRequest, questionId, type) => {
        let tagIdsArray = [];
        tags?.forEach((tag) => tagIdsArray.push(tag.id));

        let convertedQuestions = [];
        let arrayCloned = cloneDeep(actualQuestions || task?.questions);

        let question = questionId
            ? arrayCloned.find((el) => el.id === questionId)
            : arrayCloned[arrayCloned.length - 1];

        if (question && newRequest) {
            question.question = newRequest;
            question.explanation = type || question.explanation;
            question.type = 'SINGLE_SELECT';
            question.options = !!question.options.length ? question.options : [
                {
                    order: 0,
                    text: '✅ Complete!',
                    isCorrect: true,
                },
                {
                    order: 1,
                    text: '❌ Skip',
                    isCorrect: false,
                },

            ]
        }
        convertedQuestions = [...arrayCloned];

        if (user?.id) {
            await editTask({
                variables: {
                    taskId: id,
                    taskData: {
                        tagIds: tagIdsArray,
                        questions: convertedQuestions,
                    },
                    isOwner: editOthersCourses,
                },
            });
            successMessage('step');

            if (updateTask) {
                await updateTask({ fetchPolicy: "network-only" });
            }
        } else {
        }
        setTaskNotLogged((prev) => {
            let taskLocalNew = { ...prev };
            taskLocalNew.questions = convertedQuestions;
            return taskLocalNew;
        });
    };
    const handleReplaceQuestion = async (newQuestion, questionId, type, aiAnswer) => {
        let tagIdsArray = [];
        tags.forEach((tag) => tagIdsArray.push(tag.id));

        let arrayCloned = cloneDeep(actualQuestions || task?.questions);
        let question = arrayCloned.find((el) => el.id === questionId);
        let explanation = newQuestion.explanation || '';

        if (question) {
            question.question = newQuestion.question;
            question.options = newQuestion.options || [];
            question.answer = newQuestion.answer || '';
            question.type = newQuestion.type || 'SINGLE_SELECT';
            question.explanation = (type ? type + ' ' : '') + explanation;
        }

        if (userIsAllowGenerateContent) {
            await editTask({
                variables: {
                    taskId: id,
                    taskData: {
                        tagIds: tagIdsArray,
                        questions: [...arrayCloned],
                    },
                    isOwner: editOthersCourses,

                },
            });
            if (updateTask) {
                await updateTask({ fetchPolicy: "network-only" });
            }
            successMessage('step');

        } else if (user?.id) {
            await onUpdateResultDescription(aiAnswer)
        }

        actualQuestions = [...arrayCloned];

        setTaskNotLogged((prev) => {
            let taskLocalNew = { ...prev };
            taskLocalNew.questions = [...arrayCloned];
            return taskLocalNew;
        });

    };
    const handleDuplicateQuestion = async (questionId, stepDescription, notRedirect, empty) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_create_question_message' }),
            key: 'step'
        });
        let index = task?.questions?.findIndex((el) => el.id === questionId);
        let convertedQuestions = [...task.questions];
        let newQuestion = {
            type: "DEFAULT", //
            title: stepDescription,
            question: empty ? stepDescription : '',
            order: index + 1,
        };
        convertedQuestions?.splice(index + 1, 0, newQuestion);
        for (let i = index + 2; i < convertedQuestions?.length; i++) {
            let convertedQuestionsClone = { ...convertedQuestions[i] }
            convertedQuestionsClone.order = convertedQuestions[i]?.order + 1;
            convertedQuestions[i] = convertedQuestionsClone;
        }

        actualQuestions = convertedQuestions;
        try {
            const { data } = await handleEditQuestions(convertedQuestions);
            if (!notRedirect && data) {
                const nextStepId = data.editTask?.questions?.[index + 1]?.id;
                const searchParams = new URLSearchParams(search);
                searchParams.set('step', nextStepId.toString());
                history.push({ search: searchParams.toString() });
            }
            handleChangeProgress({ key: 'step' });
            return data.editTask?.questions[index + 1];
        } catch (error) {
            handleChangeProgress({ key: 'step' });
            errorMessage(error);
        }
        handleChangeProgress({ end: true })
    }
    const handleDeleteQuestion = async (questionId) => {
        let index = task?.questions?.findIndex((el) => el.id === questionId);

        let convertedQuestions = cloneDeep(task.questions);
        convertedQuestions.splice(index, 1);

        for (let i = index; i < convertedQuestions.length; i++) {
            convertedQuestions[i].order = convertedQuestions[i].order - 1;
        }

        setInProgressNextStep("");
        actualQuestions = convertedQuestions;
        const data = await handleEditQuestions(convertedQuestions);

        if (data) {
            const nextStepId = task?.questions?.[index + 1]?.id;
            const searchParams = new URLSearchParams(search);
            searchParams.set('step', nextStepId?.toString());
            history.push({ search: searchParams?.toString() });
        }
    }
    const takeResult = async () => {
        await getResult({
            variables: { taskId: task?.id },
            fetchPolicy: "network-only",
        });
    }

    useEffect(() => {
        setStepId(values?.step)
    }, [values]);

    useEffect(() => {
        NEW_CONTENT = taskContent || '';
    }, [taskContent])

    useEffect(() => {
        setResultState(result);
    }, [result])

    //generate content
    const lessThan20Tasks = !!event && event?.tasks?.length < 21;
    const { getSafetyModel } = useConditionalVoiceMode()

    useEffect(() => {

        if (user?.defaultVoiceId) {
            (async () => {
                const model = await getModelFromAudioId(user.defaultVoiceId);

                if (!model?.model) {
                    setVoiceMode(model);
                } else {
                    const safetyModel = await getSafetyModel(user?.id, model);
                    setVoiceMode(safetyModel);
                }
            })();
        }
    }, [user?.defaultVoiceId])
    useEffect(() => {
        if (!!event && userIsAllowGenerateContent && lessThan20Tasks) {
            if ((!task?.description && !descriptionInProgress)) {
                if (!voiceMode) {
                    setIsModalVisible(true);
                } else {
                    (async () => {
                        await onRegenerate(task?.source);
                        descriptionInProgress = true
                    })()
                }
            }
        }

    }, [task?.description, event?.id, voiceMode?.value]);

    useEffect(() => {
        setTaskContent(task?.description);
        (async () => await getResults({
            variables: {filter: {taskId: task?.id}},
            fetchPolicy: 'network-only'
        }))();
    }, [task?.id, task?.description]);

    useEffect(() => {
        if (!!user?.id) {
            (async () => await getResultsByUserid({
                variables: {filter: {userId: user?.id}},
                fetchPolicy: 'network-only'
            }))();
            (async () => await takeResult())();
        }
    }, [user?.id]);

    useEffect(() => {
        descriptionInProgress = false;
    }, []);

    const successMessage = (content) => {
        message.success(`New ${content} was saved`, 3);
    };
    const errorMessage = () => {
        message.error('Ai did not respond, reload the page', 3);
    };
    return <>
        <div className='tasks-page'>
            {(!!task?.questions?.length || !!task?.taskInEvents?.length) ? (
                <TaskCourseArticleComponent
                    {...other}
                    task={userIsAllowGenerateContent ? task : taskNotLogged}
                    event={event}
                    results={results}
                    result={resultState}
                    updateResults={updateResults}
                    setResult={setResultState}
                    user={user}
                    taskContent={taskContent}

                    handleRegenerate={onRegenerate}
                    handleEditTask={handleEditTask}
                    handleEditQuestion={handleEditQuestion}
                    handleReplaceQuestion={handleReplaceQuestion}
                    handleEditVideoTask={handleEditVideoTask}
                    handleCheckResultAi={handleCheckResultAi}

                    highlightedRequest={highlightedRequest}
                    handleMouseUp={handleMouseUp}
                    setInProgressHighlight={setInProgressHighlight}
                    setHighlightedNode={setHighlightedNode}
                    setHighlightedRequest={setHighlightedRequest}
                    highlightedNode={highlightedNode}
                    inProgressHighlight={inProgressHighlight}
                    handlePlanGenerate={handlePlanGenerate}
                    handleDuplicateQuestion={handleDuplicateQuestion}
                    handleDeleteQuestion={handleDeleteQuestion}
                    updateTask={updateTask}
                    handleCreateQuestion={handleCreateQuestion}

                    setInProgress={handleChangeProgress}
                    inProgress={inProgress}
                    inProgressNextStep={inProgressNextStep}
                    setInProgressNextStep={setInProgressNextStep}

                    stepId={stepId}
                    setStepId={setStepId}
                    onUpdateResultDescription={onUpdateResultDescription}
                    generateFromVideo={sourceFromVideo}
                    setGenerateFromVideo={setSourceFromVideo}
                    workspace={workspace}
                    questionStarted={questionStarted}
                    isQuestionProgress={isQuestionProgress}
                    isJoinRequired={isJoinRequired}
                    taskResult={taskResult}
                    tagsRenew={tagsRenew}
                    userIsAllowGenerateContent={userIsAllowGenerateContent}
                    progressCounter={progressCounter}
                />
            ) : (
                <TaskArticleComponent
                    {...other}
                    task={userIsAllowGenerateContent ? task : taskNotLogged}
                    userIsAllow={userIsAllowGenerateContent}
                    result={resultState}
                    updateResults={updateResults}
                    user={user}
                    highlightedRequest={highlightedRequest}
                    handleMouseUp={handleMouseUp}
                    handleEditTask={handleEditTask}
                    handleEditQuestion={handleEditQuestion}
                    handleEditVideoTask={handleEditVideoTask}
                    handleCheckResultAi={handleCheckResultAi}
                    updateTask={updateTask}
                    handleReplaceQuestion={handleReplaceQuestion}
                    setInProgressHighlight={setInProgressHighlight}
                    // setInProgress={setInProgress}
                    inProgress={inProgress}
                    inProgressNextStep={inProgressNextStep}
                    setInProgressNextStep={setInProgressNextStep}
                    inProgressHighlight={inProgressHighlight}
                    stepId={stepId}
                    setStepId={setStepId}
                    workspace={workspace}
                    taskResult={taskResult}
                    tagsRenew={tagsRenew}
                />
            )}
        </div>
        <Modal
            title="Select Voice for AI videos"
            visible={isModalVisible}
            footer={null}
            closable={false}
            header={null}
            maskClosable={false}
        >
            <VoiceSelector
                voiceMode={voiceMode}
                defaultVoice={user?.defaultVoiceId}
                setVoiceMode={(selectedVoice) => {
                    setVoiceMode(selectedVoice);
                    setIsModalVisible(false);
                }}
            />
            <p className="text_secondary wrapper_content-section">
                { /* TODO: Need translation */}

                Click on the "default" button to save your voice choice for future videos.
            </p>
        </Modal>
    </>
};
