import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

let TEACHER;

export const useGetTeacher = (userId) => {
    const [queryGetTeacher, { data, loading, error }] = useLazyQuery(GET_TEACHER, {
        fetchPolicy: 'network-only'
    });

    const getTeacher = async  (userId) => {
        if (TEACHER?.userId !== userId) {
            const result = await queryGetTeacher({
                variables: {
                    userId
                }
            });
            TEACHER = result.data?.teacher;
        }
        return TEACHER;
    }

    useEffect(() => {
        if (userId) {
            (async () => await getTeacher(userId))();
        }
    }, [userId]);

    return {
        loading,
        error,
        teacher: data?.teacher,
        getTeacher
    }
}

const GET_TEACHER = gql`
    query GetTeacher($userId: Int) {
        teacher(userId: $userId) {
            userId
            cost
            description
            lessonType
            picture
            assistantId
            name
        }
    }
`;