import React from 'react';
import { Button } from 'antd';
import { LoaderComponent} from '../../common';
import { VoiceSelector } from '../../common/VideoIframe/VideoEditor.component/VoiceSelector';
import { PlayCircleFilled } from '@ant-design/icons';
import './scss/index.scss'
import { useIntl } from 'react-intl';

export const TextToAudioComponent = (props) => {
    const {
        loading,
        error,
        voice,
        audioUrl,
        isLimitReached,
        onGetAudio,
        onChangeVoice,
        ...other
    } = props;
    const { formatMessage } = useIntl();

    return (
        <>
            { !audioUrl && (
                <div className="text-to-audio-component">
                    <PlayCircleFilled style={{ fontSize: '30px', opacity: 0.1 }} className='margin_right' />
                    <VoiceSelector voiceMode={voice} setVoiceMode={onChangeVoice} {...other} />
                    {loading ? (
                        <LoaderComponent type="inline" label="Audio narration is generating in the background" />
                    ) :
                        <Button htmlType="button"
                            size="small"
                            disabled={!voice}
                            onClick={onGetAudio}
                        >
                            <span>{formatMessage({ id: 'generate_audio' })}</span>
                        </Button>
                    }

                    {error && (
                        <div className="error">{error}</div>
                    )}
                </div>
            )}

            {/*{isLimitReached && userIsAllow && (*/}
            {/*    <div className="text-to-audio-component">*/}
            {/*        <PlayCircleFilled style={{ fontSize: '30px' }} className='margin_right' />*/}
            {/*        {formatMessage({ id: 'generate_audio' })} <ForCreditsLinkComponent formatMessage={formatMessage} />*/}
            {/*    </div>*/}

            {/*)}*/}
            {/* <a href={audioUrl} target="_blank" rel="noreferrer">Audio file</a> */}
        </>

    )
}