import './scss/index.scss';
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { noop } from 'lodash';
import { Svg, useTrackCredits } from '../../../common';
import { useGetCustomVoice, useGetVoices } from '../../../voices/hooks';
import { useLocalePrefix } from '../../../lang';
import { FirebaseContext } from '../../../../context/firebase/firebaseContext';
import { useEditUser } from '../../../common/graphQL';
import { TYPES_VOICE } from '../../../constant';

export const VoiceSelector = ({
    setVoiceMode = noop,
    voiceMode,
    // requiredCredits,
    warnKey,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const { isLimitReached, currentCredits } = useTrackCredits();
    const userAllowCustomVoice = !isLimitReached;
    const { customVoice } = useGetCustomVoice();
    const elevenlabVoices = useGetVoices();
    const [localePrefix] = useLocalePrefix();
    const onModeChange = (value) => { setVoiceMode(value) }
    const { user, workspace } = useContext(FirebaseContext);
    const { loading, editUser } = useEditUser();

    const [audioSample, setAudioSample] = useState(null);
    const [defaultVoice, setDefaultVoice] = useState(null);

    const saveDefaultVoiceId = async (voice) => {
        if (user.id) {
            await editUser({
                defaultVoiceId: voice.value
            });
        }
    }
    const customVoiceData = useMemo(() => {
        if (!customVoice) {
            return null;
        }
        const voice = {
            label: customVoice.name,
            value: customVoice.voiceId,
            model: 'custom-voice'
        }
        return voice;
    }, [customVoice]);

    const voices = useMemo(() => {
        let voicesList = [...TYPES_VOICE];
        if (customVoiceData) {
            voicesList = [customVoiceData, ...voicesList];
        }
        if (elevenlabVoices) {
            voicesList = [...voicesList, ...elevenlabVoices];
        }
        return voicesList;
    }, [TYPES_VOICE, elevenlabVoices, customVoiceData]);

    const onHoverVoiceOption = (value) => {
        if (audioSample) {
            audioSample.pause();
            audioSample.currentTime = 0;
        }

        const newAudioSample = new Audio(voices.find(type => type.value === value).example);
        setAudioSample(newAudioSample);
        newAudioSample?.play();
    };

    const onLeaveVoiceOption = () => {
        if (audioSample) {
            audioSample.pause();
            audioSample.currentTime = 0;
        }
    };

    useEffect(() => {
        if (user?.defaultVoiceId) {
            const defaultVoice = voices.find(voice => voice.value === user.defaultVoiceId);
            if (defaultVoice) {
                if (defaultVoice.model) {
                    if (userAllowCustomVoice) {
                        setDefaultVoice(defaultVoice);
                        if (!voiceMode) {
                            setVoiceMode(defaultVoice);
                        }
                    }
                } else {
                    setDefaultVoice(defaultVoice);
                    if (!voiceMode) {
                        setVoiceMode(defaultVoice);
                    }
                }
            }
        }
    }, [user?.defaultVoiceId, voices, voiceMode, setVoiceMode]);
    const placeholder = voiceMode ? voiceMode.label : (defaultVoice ? defaultVoice?.label : formatMessage({ id: 'select_voice' }));

    const menu = (
        <Menu
            onClick={({ key }) => {
                const selectedVoice = voices.find(type => type.value === key);
                onModeChange(selectedVoice);
            }}
            style={{ maxHeight: '400px', overflowY: 'scroll' }}
        >
            <div className='menu-item-group'>
                <img
                    src={"https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/OpenAI_Logo.svg/800px-OpenAI_Logo.svg.png?20210328085757"}
                    className="api-button-component__image margin_right"
                    alt="Developed with Open AI"
                    style={{ maxHeight: 20, marginRight: 16 }}
                />
            </div>

            <Menu.ItemGroup>
                {voices.filter(type => !type.model).map((type) => (
                    <Menu.Item key={type.value}>
                        <Space
                            size="large"
                            style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                        >
                            {type.label}
                            <div>
                                <Button
                                    size="small"
                                    type='text'
                                    className={user?.defaultVoiceId !== type.value ? "hover-button margin_right" : "margin_right"}
                                    onClick={() => saveDefaultVoiceId(type)}
                                >
                                    default
                                </Button>
                                <Button
                                    size="small"
                                    type='text'
                                    onMouseEnter={() => onHoverVoiceOption(type.value)}
                                    onMouseLeave={onLeaveVoiceOption}
                                >
                                    <Svg name=':icon/play' className='menu-icon' />
                                </Button>
                            </div>
                        </Space>
                    </Menu.Item>
                ))}
            </Menu.ItemGroup>

            <div className='menu-item-group'>
                <div>
                    <img
                        src={"https://upload.wikimedia.org/wikipedia/en/7/75/ElevenLabs_logo_2.png"}
                        className="api-button-component__image margin_right"
                        alt="ElevenLabs"
                        style={{ maxHeight: 11, verticalAlign: "baseline" }}
                    />
                    {!workspace && (
                        <Link to="/credits/">{formatMessage({ id: 'for_ai_credits' })}</Link>
                    )}
                    {!!workspace && (
                        <a href="https://unschooler.me/credits"
                            rel="noreferrer"
                            target="_blank">
                            {formatMessage({ id: 'for_ai_credits' })}
                        </a>
                    )}
                </div>
                <small className='text_secondary'> You have {currentCredits || 0} AI credits</small>
                {/* {requiredCredits && <small className='text_secondary'> Required: {requiredCredits }</small>} */}
            </div>

            <Menu.ItemGroup>
                {customVoiceData ? (
                    <Menu.Item key={customVoiceData.value} disabled={!userAllowCustomVoice}>
                        <Space
                            size="large"
                            style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                        >
                            {customVoiceData.label}
                            <Button
                                size="small"
                                type='text'
                                className={user?.defaultVoiceId !== customVoiceData.value ? "hover-button margin_right" : "margin_right"}
                                onClick={() => saveDefaultVoiceId(customVoiceData)}
                                disabled={!userAllowCustomVoice}
                            >
                                default
                            </Button>
                        </Space>
                    </Menu.Item>
                ) :
                    <Menu.ItemGroup>
                        <Menu.Item >
                            <Link to={`${localePrefix}/profile`}>
                                {formatMessage({ id: 'custom_voice_title' })} with credits
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                }

                {voices.filter(type => type.model === 'eleven').map((type) => (
                    <Menu.Item key={type.value} disabled={!userAllowCustomVoice}>
                        <Space
                            size="large"
                            style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                        >
                            {type.label}
                            <div>
                                <Button
                                    size="small"
                                    type='text'
                                    className={user?.defaultVoiceId !== type.value ? "hover-button margin_right" : "margin_right"}
                                    onClick={() => saveDefaultVoiceId(type)}
                                    disabled={!userAllowCustomVoice}
                                >
                                    default
                                </Button>
                                <Button
                                    size="small"
                                    type='text'
                                    onMouseEnter={() => onHoverVoiceOption(type.value)}
                                    onMouseLeave={onLeaveVoiceOption}
                                >
                                    <Svg name=':icon/play' className='menu-icon' />
                                </Button>
                            </div>
                        </Space>
                    </Menu.Item>
                ))}
            </Menu.ItemGroup>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button
                type='text'
                size="small"
                className='margin_right'
                {...other}
            >
                {placeholder}
                <DownOutlined />
            </Button>
        </Dropdown>
    );
}
