import './scss/index.scss';
import React, { useContext } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useLocalePrefix } from '../../lang';
import { useIntl } from "react-intl";

import {
    useTrackClick,
    Svg,
    DripExplanation,
    // TagComponent,
    CircleIndexComponent,
    getTitle,
    getType,
} from '../../common';
import { EmbedContext } from '../../../context/embed';
// import { AchievementsCard } from '../../achevements/Achievements.component';
// import { JoinCourse } from '../../event/JoinCourse.component/JoinCourse.component';
import { Loader } from '../../Loader';

export const Thread = ({
    taskId,
    event,
    stepId,
    taskResult,
    inProgress,
    inProgressNextStep,
    workspace,
    user,
    loadingData
}) => {
    const { isEmbed } = useContext(EmbedContext) || {};
    const URL = isEmbed || '';
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();
    const trackClick = useTrackClick();
    const panelClass = classnames('connected-panel', {
        'disable-el': inProgressNextStep || inProgress,
    });
    const canCreateEvent = (event?.creator?.id === user?.id);
    if (!taskResult) {
        return <Loader />;
    }
    return (
        <div className={panelClass}>
            {event &&
                <div>
                    <span className='text_secondary'>{formatMessage({ id: "course_title" })}:</span>
                    <Link className='event-cur-item__title' to={{ pathname: `${localePrefix}/${URL}online-courses/${event?.alias}-${event?.id}`, state: { workspace } }}>
                        <h3 className='link-secondary '>{event?.title?.substring(0, 60)}</h3>
                    </Link>
                </div>
            }

            {event?.drip &&
                <DripExplanation classText='text_secondary' formatMessage={formatMessage} event={event} />
            }
            {taskResult?.map((el, i) =>
                (!el?.isDraft || (el?.isDraft && canCreateEvent)) && (
                    <div key={`${el?.id}_${i}`}>
                        <div
                            className={el?.id === taskId ? 'connected-panel__item card-active' : 'connected-panel__item'}
                            key={`task-result_${el?.id}-${i}`}
                            style={{
                                // color: el?.tags?.[0]?.subject?.color,
                                opacity: el?.isDraft ? 0.3 : 1
                            }}
                        >
                            <div className={`answer-component Answer_container ${!el?.description ? 'empty' : ''}`}>
                                <CircleIndexComponent
                                    isActive={el?.id === taskId}
                                    check={event?.isSubscribe && el?.stateResult}
                                    icon={(el.isHidden || (!el?.isFree && !event?.isSubscribe && !event?.isDraft && !canCreateEvent))
                                        ? <Svg name=':icon/admin' className='menu-icon' /> : ''}
                                />

                                <Link
                                    to={`${localePrefix}/${URL}tutorials/${el?.alias}-${el?.id}`}
                                    className={
                                        el?.id === taskId
                                            ? 'connected-panel__Link connected-panel__Link_active'
                                            : 'connected-panel__Link'
                                    }
                                    onClick={() => {
                                        trackClick(
                                            `click-thread`,
                                            'nextStep',
                                            `https://unschooler.me/`
                                        );
                                    }}
                                >
                                    {el?.title}
                                </Link>
                                {el?.isDraft && <span className='draft-icon'>Draft</span>}
                            </div>

                            {el?.id === taskId && !!el?.questions?.length &&
                                <div className='connected-panel__subitem'>
                                    {el?.questions?.map((question, i) => (
                                        <div key={`question_${el?.id}-${question.id}-${i}`} className='connected-panel__subitem__item'>
                                            <CircleIndexComponent
                                                isActive={question?.id == stepId
                                                    ? 'connected-panel__Link connected-panel__Link_active'
                                                    : 'connected-panel__Link'}

                                                check={event?.isSubscribe && question.stateResult}
                                                icon={question.isHidden ? <Svg name=':icon/admin' className='menu-icon' /> : ''}
                                            />

                                            <Link
                                                to={`${localePrefix}/${URL}tutorials/${el.alias}-${el?.id}?step=${question.id}`}
                                                onClick={() => {
                                                    trackClick(
                                                        `click-thread`,
                                                        'nextStep',
                                                        `https://unschooler.me/`
                                                    );

                                                }}
                                                className={question?.id == stepId
                                                    ? 'connected-panel__Link  connected-panel__Link_active'
                                                    : 'connected-panel__Link  '
                                                }>
                                                <span className="feature">
                                                    {event && getType(question?.type, question)}
                                                    {(question?.question && getTitle(question?.question?.replace(/#/g, ''))) || getTitle(question?.title?.replace(/#/g, ''))}
                                                </span>
                                            </Link>
                                            {/* } */}

                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                ))
            }
            {event?.certificate &&
                <div className={'connected-panel__item'}>
                    <div className='connected-panel__subitem__item'>
                        <div className='answer-component Answer_container '
                        // style={{ backgroundColor: event?.tags && event?.tags?.[0]?.subject?.color + '1c' }}
                        >
                            <CircleIndexComponent
                                isActive={false}
                                check={false}
                                icon={!event?.certificate?.isAppliedForCurrentUser ? <Svg name=':icon/admin' className='menu-icon' /> : null}
                            />

                            <Link
                                to={`${localePrefix}/${URL}certificate/${event?.certificate?.id}`}
                                className={
                                    window.location.href.includes('certificate')
                                        ? 'connected-panel__Link connected-panel__Link_active'
                                        :
                                        'connected-panel__Link'
                                }
                                onClick={() => {
                                    trackClick(
                                        `click-thread`,
                                        'nextStep',
                                        `https://unschooler.me/`
                                    );
                                }}
                            >
                                {formatMessage({ id: "certificate" })}
                            </Link>
                        </div>
                    </div>
                </div>
            }

            {/* {!!tagsRenew?.length &&
                <div className='wrapper_content-section'>
                    {tagsRenew?.map((tag, i) => (
                        <div className='skills_tag_icon'>
                            <div
                                key={`skill-tag-${tag?.id}`}
                                className='skills_tag skill_progress'
                            >
                                <div className='skill_progress_incorrect' style={{ width: tag.counter?.incorrect / (tag.counter?.all / 100) + '%', }}></div>
                                <TagComponent
                                    tag={tag}
                                    key={i}
                                    link={false}
                                    countResults={tag.counter?.done &&
                                    {
                                        all: tag?.counter?.all,
                                        done: tag?.counter?.done || 0,
                                    }}
                                />
                            </div>

                            <AchievementsCard
                                type={`small`}
                                key={tag?.id}
                                achievement={tag}
                                width={80}
                            />

                        </div>
                    ))}
                </div>
            } */}

            {loadingData && (
                <Loader />
            )}
        </div >
    );
};
