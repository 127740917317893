import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { WorkspaceCreateComponent } from '../WorkspaceCreate.component';
import { COVER_PICTURES } from '../../constant';
import { MetaComponent, useCurrencyCode, baseHost } from '../../common';
import {useCreateWorkspace, useEditWorkspace, useDeleteWorkspace} from '../hooks';

export const WorkspaceCreateContainer = (props) => {
    const { user, setEditmode, workspaceProp = {}, onUpdateWorkspace, workspaces, ...other } = props;
    const { customDomain } = workspaceProp;
    const { pathname } = useLocation();
    const { useForm } = Form;
    const [form] = useForm();
    const { setFieldsValue } = form;
    const [emptyWorkspace, setEmptyWorkspace] = useState();
    const [hostError, setHostError] = useState();
    const [inProgress, setInProgress] = useState();
    const [domainLoading, setDomainLoading] = useState();
    const { formatMessage } = useIntl();
    const { changeCurrencyCode } = useCurrencyCode();
    const {createWorkspace} = useCreateWorkspace();
    const {editWorkspace} = useEditWorkspace();
    const {deleteWorkspace} = useDeleteWorkspace();
    const [removeDomainLoading, setRemoveDomainLoading] = useState();
    const [isDomain, setIsDomain] = useState(!!customDomain);

    const handleCreateWorkspace = async (data) => {
        setHostError(false);
        setInProgress(true);
        const { host, currencyCode, customScript, externalScripts: externalScriptsData } = data || {};

        // Remove not editable props, cover ad logo
        const {
            id,
            firebaseId,
            ownerId,
            status,
            isDisabled,
            eventsLists,
            invites,
            members,
            resend,
            createdAt,
            updatedAt,
            cover,
            logo,
            favicon,
            sharing,
            ...otherWorkspaceProp } = workspaceProp;
        const scriptJSON = !!customScript ? JSON.stringify(customScript) : null;
        const externalScripts = externalScriptsData?.map((item) => item.title) || [];

        const workspaceId = id;
        const {...fields} = data || {};
        const fieldsData = {};

        for (const key in fields) {
            if (fields[key] !== undefined) {
                fieldsData[key] = fields[key];
            }
        }

        const newData = {
            ...otherWorkspaceProp,
            ...(!ownerId && { ownerId: user?.id }),
            currencyCode,
            externalScripts,
            ...fieldsData,
            ...(scriptJSON && { customScript: scriptJSON }),
            ...(externalScripts && {externalScripts})
        };

        if (!workspaceId) {
            try {
                await createWorkspace({
                    variables: {
                        input: {
                            ...newData
                        }
                    }
                })
                window.location.href = `${window.location.protocol}//${host}.${baseHost}/invite`;
            } catch (err) {
                const isHostError = err.message === 'The same host exists';
                if (isHostError) {
                    setHostError(true);
                }
                setInProgress(false);
                return;
            }
        } else {
            await editWorkspace(newData, workspaceId);
            await onUpdateWorkspace();
        }
        changeCurrencyCode(currencyCode);
        setEditmode(false);
        setInProgress(false);
    }
    const handleDeleteWorkspace = async () => {
        try {
            await deleteWorkspace({
                variables: {
                    id: workspaceProp?.id
                }
            });
            window.location.href = `${window.location.protocol}//${baseHost}`; // Redirect to main page
        } catch (err) {
            console.warn(err.message);
        }
    };

    const handleRemoveDomain = async () => {
        setRemoveDomainLoading(true);
        const resp = await editWorkspace({customDomain: null}, workspaceProp?.id);
        const domain = resp?.data?.editWorkspace?.customDomain;
        setIsDomain(!!domain);
        setRemoveDomainLoading(false);
        return domain;
    }

    const collectNewWokspace = async () => {
        const synthData = {
            cover: "https://cdn.unschooler.me/web_app/workspaces/4/grid_th.png",
            description: "Courses, assessments, tests for students",
            ownerId: user?.id,
            logo: "https://cdn.unschooler.me/web_app/workspaces/App%20Store.png",
            title: user?.name + " school",
            currencyCode: 'USD'
        }
        setEmptyWorkspace(synthData);
        setFieldsValue(synthData);
    }

    useEffect(() => {
        if (workspaceProp) {
            setFieldsValue(workspaceProp);
        } else {
            (async () => await collectNewWokspace())();
            (async () => {
                await collectNewWokspace();
            })()
        }
    }, [workspaceProp])

    const metaData = useMemo(() => {
        const title = workspaceProp?.title || 'School';
        const metaTitle = `${title} on Unschooler: create courses and publish them for free.`;
        const description = formatMessage({ id: 'workspaces_description' });
        const url = `https://unschooler.me${pathname}`;

        return {
            meta: {
                meta_title: metaTitle,
                meta_description: description,
            },
            og: {
                title: metaTitle,
                image: COVER_PICTURES.COMMUNITIES,
                description,
                url,
            },
            twitter: {
                tw_title: metaTitle,
                tw_url: url,
                tw_description: description,
            },
        };
    }, [pathname, workspaceProp, formatMessage]);

    return (
        <>
            {metaData && (
                <MetaComponent
                    meta={metaData.meta}
                    og={metaData.og}
                    twitter={metaData.twitter}
                />
            )}
            <WorkspaceCreateComponent
                {...other}
                user={user}
                workspace={workspaceProp}
                emptyWorkspace={emptyWorkspace}
                handleCreateWorkspace={handleCreateWorkspace}
                isDomain={isDomain}
                form={form}
                inProgress={inProgress}
                hostError={hostError}
                removeDomainLoading={removeDomainLoading}
                domainLoading={domainLoading}
                handleDeleteWorkspace={handleDeleteWorkspace}
                setEditmode={setEditmode}
                onRemoveDomain={handleRemoveDomain}
            />
        </>
    );
};
