import './scss/index.scss';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { WorkspaceMembersContainer } from '../WorkspaceMembers.container';
import {LoginContainer, WorkspaceTitleComponent, useIsAllow, LoaderComponent} from '../../common';
import { ListEventsContainer, SimpleEventsListContainer, EventsListContainer, PaginatedListEventsContainer } from '../../events';
import { AddEventSectionContainer } from '../AddEventSection.container/AddEventSection.container';
import { AIAllFeaturesContainer } from '../../ai';
import { InvitesFormContainer } from '../InvitesForm.container/InvitesForm.container';
import { MoveCoursesToWorkspaceContainer } from '../MoveCoursesToWorkspace.container';
import { Loader } from '../../Loader';
import { InvitedUsersWorkspaceContainer } from '../InvitedUsersWorkspace.container/InvitedUsersWorkspace.container';
import { EnrollWorkspaceComponent } from '../../enroll/EnrollWorkspace.component';
import { AddStudentsFromCoursesContainer } from '../AddStudentsFromCourses.container';
import { ListBundlesContainer } from '../../bundles';
import { ROLES } from '../../constant';
import { useIsPermit } from '../../roles/hook/useCheckPermission';

export const WorkspaceComponent = (props) => {
    const { user,
        workspace,
        handleJoinWorkspace,
        members,
        membersLoading,
        isFakeLoading,
        onSendSelected,
        ...other
    } = props;
    const { formatMessage } = useIntl();
    const { id: workspaceId, firebaseId, eventsLists } = workspace;
    const [showLogin, setShowLogin] = useState();
    const [allEvents, setAllEvents] = useState([]);
    const { checkPermit, getRolePermissions } = useIsPermit()

    const useIsAllowed = useIsAllow();
    const isPaidEditor = useIsAllowed('PREMIUM', workspace) ;

    const createCourses = checkPermit(workspace, 'createCourses')
    const inviteCreators = checkPermit(workspace, 'inviteCreators')
    const inviteStudents = checkPermit(workspace, 'inviteStudents')

    const rolePermissions = getRolePermissions(workspace);
    const handleLoginUser = () => setShowLogin(true);

    return (
        <>
            <WorkspaceTitleComponent
                workspace={workspace}
                {...other}
                user={user}
            />

            <div className='page flex-container page_tag'>
                <div className='flex-left-column'>
                    {createCourses &&
                        <AIAllFeaturesContainer
                            handleLoginUser={handleLoginUser}
                            user={user}
                            workspace={workspace}
                        />
                    }
                    {rolePermissions === "User" &&
                        <EventsListContainer
                            {...other}
                            user={user}
                            title={formatMessage({ id: 'tab_events_my' })}
                            activeTab={formatMessage({ id: "tab_events_user" })}
                            createCourses={createCourses}
                        />
                    }

                    {/* list of bundles */}
                    <div className="wrapper_outer-section">
                        <ListBundlesContainer
                            {...other}
                            user={user}
                            filterData={{
                                workspaceId,
                                ...(firebaseId && {firebaseId})
                            }}
                            title={formatMessage({ id: 'course_bundles' })}
                            activeTab={formatMessage({ id: "tab_events_user" })}
                            workspace={workspace}
                            createCourses={createCourses}
                        />
                    </div>

                    {/* custom sections */}
                    {isFakeLoading ?
                        <Loader />
                        :
                        <div className="wrapper_outer-section">
                            {eventsLists?.map((listData, index) => (
                                <div key={`workspace_events_list_${listData?.title?.replace(' ', '_')}`}>
                                    {!!listData?.tags?.length &&
                                        <ListEventsContainer
                                            {...other}
                                            title={listData?.title}
                                            index={index}
                                            eventsLists={eventsLists}
                                            filterData={{
                                                workspaceId,
                                                tagsIds: listData?.tags,
                                                ...(firebaseId && {firebaseId})
                                            }}
                                            typeProp="card"
                                            user={user}
                                            setAllEvents={setAllEvents}
                                            workspace={workspace}
                                            createCourses={createCourses}
                                        />
                                    }
                                    {!!listData?.courses?.length &&
                                        <SimpleEventsListContainer
                                            title={listData?.title}
                                            eventsList={listData?.courses}
                                            openFirtsLesson={true}
                                            index={index}
                                            eventsLists={eventsLists}
                                            typeProp="card"
                                            setAllEvents={setAllEvents}
                                            workspace={workspace}
                                            createCourses={createCourses}
                                            {...other}
                                        />
                                    }
                                </div>
                            ))}
                            <>
                                {createCourses &&
                                    <AddEventSectionContainer
                                        user={user}
                                        setEditmode={setShowLogin}
                                        workspaceProp={workspace}
                                        {...other}
                                    />
                                }
                                {/* all courses of this school */}
                                <PaginatedListEventsContainer
                                    {...other}
                                    title={formatMessage({ id: 'tab_events_all' })}
                                    eventsLists={eventsLists}
                                    filterData={{
                                        workspaceId,
                                        ...(firebaseId && {firebaseId})
                                    }}
                                    typeProp="card"
                                    limit={30}
                                    user={user}
                                    setAllEvents={setAllEvents}
                                    createCourses={isPaidEditor}
                                    workspace={workspace}
                                />

                                {createCourses &&
                                    <MoveCoursesToWorkspaceContainer
                                        workspace={workspace}
                                        user={user}
                                        filter={{
                                            creatorId: user?.id
                                        }}
                                        afterFilter={'NotFromWorkspace'}
                                        onSendSelected={onSendSelected}
                                        {...other}
                                    />
                                }
                            </>
                        </div>
                    }

                </div>

                {/* right panel */}
                {(inviteStudents || workspace?.stripeProductId) && (
                    <div className='flex-right-column sticky_enroll'>
                        {inviteStudents &&
                            <div className='profile-block profile-workspace-wrapper'>
                                <>
                                    <Link to={`/students`} className="ant-btn ant-btn-link link-btn">
                                        <span>
                                            {formatMessage({ id: 'members_statistics' })}
                                        </span>
                                    </Link>
                                    <hr />
                                    {inviteCreators && <div>
                                        {membersLoading && (
                                            <LoaderComponent type="inline"/>
                                        )}
                                        {!membersLoading && (
                                            <>
                                                <WorkspaceMembersContainer user={user}
                                                                           members={members}
                                                                           workspace={workspace}
                                                                           memberGroup={ROLES[0].permission}
                                                                           {...other}
                                                />
                                                <WorkspaceMembersContainer user={user}
                                                                           members={members}
                                                                           workspace={workspace}
                                                                           memberGroup={ROLES[1].permission}
                                                                           {...other}
                                                />
                                                <WorkspaceMembersContainer user={user}
                                                                           members={members}
                                                                           workspace={workspace}
                                                                           memberGroup={ROLES[2].permission}
                                                                           {...other}
                                                />
                                                <WorkspaceMembersContainer user={user}
                                                                           members={members}
                                                                           workspace={workspace}
                                                                           memberGroup={ROLES[3].permission}
                                                                           {...other}
                                                />
                                            </>
                                        )}
                                        <hr />
                                    </div>}


                                    {inviteStudents &&
                                        <div>
                                            <small className='text_secondary block-el'>
                                                {formatMessage({ id: 'students' })}</small>
                                            {membersLoading && (
                                                <div>
                                                    <LoaderComponent type="inline"/>
                                                </div>
                                            )}
                                            {!membersLoading && (
                                                <WorkspaceMembersContainer user={user}
                                                                           members={members}
                                                                           workspace={workspace}
                                                                           memberGroup={ROLES[4].permission}
                                                                           onSendSelected={onSendSelected}
                                                                           {...other}/>
                                            )}
                                            <AddStudentsFromCoursesContainer
                                                workspace={workspace}
                                                allEvents={allEvents}
                                                user={user}
                                                {...other}
                                            />
                                            <hr />
                                        </div>}

                                    <InvitedUsersWorkspaceContainer
                                        workspace={workspace}
                                        user={user}
                                        formatMessage={formatMessage}
                                        {...other}
                                    />

                                    <InvitesFormContainer/>
                                </>
                            </div>
                        }
                        {workspace?.stripeProductId &&
                            <EnrollWorkspaceComponent
                                workspace={workspace}
                                events={allEvents}
                                onLogin={handleLoginUser}
                            />
                        }
                    </div>
                )}

                <LoginContainer
                    showLogin={showLogin}
                    setCancelLogin={setShowLogin}
                    onLoginUser={handleLoginUser}
                />
            </div >
        </>
    );
};
