/* global YT */
import './scss/index.scss';

import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import Script from 'react-load-script';

import { YoutubeCaptionsContainer } from '../../youtube';

const API_URL = 'https://www.youtube.com/iframe_api';
let isCanInit = true;
let currentVideoSrc = null;

export const VideoPlayer = ({
    // handleChangeVideo,
    // videoSet,
    // videoId,
    handleEditVideoTask,
    inProgress,
    setInProgress,
    videoSrc,
    task,
    user,
    showCaptions,
    summary,
    captions,
    ...other
}) => {
    const playerHtml = <div id='player' />;
    const playerRef = useRef();
    const wrapperRef = useRef();
    const currentTimestamp = useRef(0);

    const initPlayer = (videoSrcData) => {
        isCanInit = false;
        const arr = !!videoSrcData ? videoSrcData?.split('/') : null;
        const videoId = arr && arr[arr.length - 1];

        if (!videoId) {
            return null;
        }

        const init = () => {
            if (window?.YT?.Player) {
                playerRef.current = new window.YT.Player('player', {
                    className: 'videoIframeStyle',
                    height: '100%',
                    width: '100%',
                    videoId,
                    // startSeconds: currentTimestamp.current,
                    playerVars: {
                        rel: 0,
                        startSeconds: currentTimestamp.current,
                        origin: window.location.origin
                    },
                    // do this if 
                    // events: {
                    //     onReady: (w) => {
                    //         if (!!w?.target?.playerInfo?.playerState && w?.target?.playerInfo?.playerState < 1) {
                    //             !!videoSet?.length && handleChangeVideo();
                    //         }
                    //     },
                    // },
                });
                isCanInit = true;
                currentVideoSrc = videoSrcData;
            } else {
                setTimeout(init, 100);
            }

        };

        init();
    };
    const reloadPlayer = (videoSrc) => {
        console.log(videoSrc, 'TTTTTTTT videoSrc');
        document.getElementById('player')?.remove();
        const div = document.createElement('div');
        div.setAttribute('id', 'player');
        wrapperRef?.current?.append(div);
        initPlayer(videoSrc);
    };

    useEffect(() => {
        if (isCanInit && !playerRef?.current && videoSrc) {
            initPlayer(videoSrc);
        }
    }, [window?.YT, videoSrc]);

    useEffect(() => {
        if (videoSrc && (videoSrc && playerRef?.current ||
            (currentVideoSrc && currentVideoSrc !== videoSrc))
        ) {
            reloadPlayer(videoSrc);
        }
    }, [videoSrc]);

    if (!videoSrc) { return null; }

    const videoClassNames = classnames('videoContainerStyle videoOnTop', {});
    return (
        <div className="videoset">
            <div className={videoClassNames}>
                {!window.YT && <Script url={API_URL} />}

                <div className='playerContainerStyle' ref={wrapperRef}>
                    {playerHtml}
                </div>

                {(captions && showCaptions) && (
                    <YoutubeCaptionsContainer
                        currentTimestamp={currentTimestamp}
                        playerRef={playerRef}
                        inProgress={inProgress}
                        isVisible={captions}
                        reloadPlayer={reloadPlayer}
                        captions={captions}
                        videoSrc={videoSrc}
                        task={task}
                        user={user}
                        handleEditVideoTask={handleEditVideoTask}
                        summary={summary}
                        {...other}
                    />
                )}
            </div>
        </div>
    );
};
