import React, { useMemo, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { Program } from '../Pages/Program';
import { Community } from '../Pages/Community';
import { Workspace } from '../Pages/Workspace';
import { Workspaces } from '../Pages/Workspaces';
import { Payments } from '../Pages/Payments';
import { Checkout } from '../Pages/Checkout';
import { Login } from '../Pages/Login';
import { PageForbidden } from '../Pages/PageForbidden';
import { Admin } from '../Pages/Admin';
import { Landing } from '../Pages/Landing.js';
import { LandingVideo } from '../Pages/LandingVideo.js';
import { Logout } from './logout';
import { CreateTaskDrawer } from './task/CreateTaskDrawer';
import { AdminPayments } from '../Pages/AdminPayments';
import { GuadlineComponent } from './admin/Guadline.component';
import { CleanJobsComponent } from './admin/CleanJobs.component';
import { WorkspacesContainer } from './admin/Workspaces';
import { EventPage } from './event/eventPage/EventPage';
import { BundlePage } from '../Pages/BundlePage.js';
import { HeaderWorkspace, HeaderGuest } from './headers';
import { Embed } from '../Pages/Embed.js'
import { ProtectedRoleRoute } from './common/protectedRoute';
import { Success } from '../Pages/Success';
import { Affiliate } from '../Pages/Affiliate';
import { Reports } from '../Pages/Reports';
import { SkillsTree } from '../Pages/SkillsTree';
import { Careers } from '../Pages/Careers';
import { Promoes } from './admin/Promo/Promoes';
import { Promo } from './admin/Promo/Promo';
import { CreatePromo } from './admin/Promo/CreatePromo';
import { Config } from './admin/Config/Config';
import { CallbackCalendar } from '../Pages/CallbackCalendar';
import DevContainer from './admin/Dev/Dev.container/Dev.container';
import { Promocodes } from './admin/Promocode/Promocodes';
import { Promocode } from './admin/Promocode/Promocode';
import { TasksByOrderContainer } from './admin/TasksByOrder.container';
import { PromocodeUsedByUsers } from './admin/Promocode/PromocodeUsedByUsers';
import { CreateEvent } from '../Pages/CreateEvent';
import { Events } from '../Pages/Events';
import { TermsMentor } from '../Pages/TemsMentor';
import { YoutubeCallback } from '../Pages/YoutubeCallback';
import { ProfilePage } from '../Pages/ProfilePage';
import { MyProfile } from './profile'
import { SubscriptionUser } from '../Pages/SubscriptionUser';
import { ThemeSwitcherAutoComponent } from './common/ThemeSwitcherAuto.component'
import { Students } from '../Pages/Students.js'
import { ConfirmEmail } from '../Pages/ConfirmEmail';
import { Rating } from '../Pages/Rating.js';

import { useLocalePrefix, useTrimPrefixPath } from './lang';
import { useAddIntegrations } from './integrations';
import { TaskArticlePage } from '../Pages/TaskArticlePage';
import { EditTaskPage } from '../Pages/EditTaskPage';
import { CorrectSkills } from '../Pages/CorrectSkills';
import { AccessDenied } from '../Pages/AccessDenied';
import { Invite } from '../Pages/Invite';
import { ResetPassword } from '../Pages/ResetPassword';
import { TermsStudent } from '../Pages/TermsStudent';
import { PolicyStudent } from '../Pages/PolicyStudent';
import { NavBar } from './NavBar';
import { LoaderComponent, useIsAllow, hostname, useCustomScript } from "./common";
import { CookieComponent } from './landing/Cookie.component/Cookie.component';
import { VideoCustomPlayerPreview } from './common/VideoIframe/VideoCustomPlayerPreview';
import { useConfirmEmail, useConfirmEmailToken } from './user';
import { FooterContainer } from './footer';
import { Certificate } from '../Pages/Certificate';
import { UserCertificate } from '../Pages/UserCertificate';
import { Credits } from '../Pages/Credits';
import { TestPage } from '../Pages/TestPage';
import { useIsPermit } from './roles/hook/useCheckPermission';

const REDIRECT_LIST = [
    '/tutorials',
    '/online-courses',
    '/students',
    '/profile/myprofile',
    '/terms',
    '/policy',
    '/affiliate',
    '/terms-mentor',
    '/skills',
];

const RTL_LIST = ['/ar', '/he'];

export const Main = ({ user, loading, workspace, workspaceLoading, updateUserData, onUpdateWorkspace }) => {
    const location = useLocation();
    const { state, pathname } = location || {};
    const isEmbed = window.location.pathname.includes('embed')
    const [localePrefix, idRedirectLocation] = useLocalePrefix();
    const getTrimPath = useTrimPrefixPath();
    const { confirmEmail } = useConfirmEmail();
    const { confirmEmailToken } = useConfirmEmailToken();
    const addCustomScript = useCustomScript();

    // Add integrations
    useAddIntegrations();

    const isRedirect = useMemo(
        () => REDIRECT_LIST.includes(pathname),
        [pathname]
    );
    const userIsAllowd = useIsAllow();
    const isAllowCreator = userIsAllowd("CREATOR");
    const isAllowPro = userIsAllowd("PRO");
    const background = state?.background;
    const [isEditMode, setIsEditMode] = useState();
    const isLoading = useMemo(() => loading || workspaceLoading, [loading, workspaceLoading]);
    const { checkPermit } = useIsPermit()
    const inviteStudents = checkPermit(workspace, 'inviteStudents')

    useEffect(() => {
        if (RTL_LIST.includes(localePrefix)) {
            document.body.setAttribute('dir', 'rtl');
        } else {
            document.body.removeAttribute('dir');
        }
    }, [localePrefix]);

    const showInvitePage = useMemo(() => {
        let result = false;
        const { loginOnly, inviteOnly } = workspace || {};
        if (!!user?.id) {
            if (inviteOnly) {
                const invite = !!workspace?.invites?.find((invite) => invite?.email === user?.email);
                const memberOfWorkspace = !!workspace?.members?.find((member) => member.id === user?.id) || (workspace?.ownerId === user?.id);
                result = memberOfWorkspace ? false : !invite;
            }
        } else {
            result = loginOnly || inviteOnly;
        }
        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id, workspace]);

    useEffect(() => {
        if (user?.isNew && !user?.emailVerified && !user?.emailVerified && !workspaceLoading) {
            (async () => await confirmEmail())();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.isNew, confirmEmail]);

    useEffect(() => {
        if (user?.isInvited && !user?.emailVerified) {
            (async () => {
                await confirmEmailToken({
                    variables: {
                        token: 'invitedUser'
                    }
                });
                await updateUserData(user?.id)
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.isInvited])

    useEffect(() => {
        const customScript = workspace?.customScript;
        if (workspace?.customScript || workspace?.externalScripts?.length) {
            addCustomScript({
                customScript: workspace?.customScript,
                externalScripts: workspace?.externalScripts
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspace?.customScript, workspace?.externalScripts]);

    if (isLoading) return <LoaderComponent type="overlay" />;

    const hideNavBar = hostname || isEmbed;
    return (
        <>
            {!hideNavBar &&
                <NavBar user={user} isAllowPro={isAllowPro} />
            }

            <main className={hideNavBar ? 'main_embed' : 'main'}>
                {/* headers in workspace */}
                {(hostname && workspace) ?
                    <Switch>
                        {isRedirect && <Redirect from={pathname} to={pathname + '/'} />}
                        <Route
                            exact
                            path={[
                                `${localePrefix}/`,
                                `${localePrefix}/invite`,
                                `${localePrefix}/profile/`,
                                `${localePrefix}/students/`,
                                `${localePrefix}/online-courses/:alias`,
                                `${localePrefix}/user-certificate/:id`,
                                `${localePrefix}/bundles/:id`,
                                `${localePrefix}/certificate/:id`,
                                `${localePrefix}/tutorials/:by/:id`,
                                `${localePrefix}/tutorials/:id`,
                                `${localePrefix}/reset-password`,
                                `${localePrefix}/email-verification`,
                                `${localePrefix}/confirm-email`,
                                `${localePrefix}/terms`,
                                `${localePrefix}/policy`
                            ]}
                            render={(props) =>
                                <HeaderWorkspace {...props}
                                    user={user}
                                    hostname={hostname}
                                    workspace={workspace}
                                    isEditMode={isEditMode}
                                    onUpdateWorkspace={onUpdateWorkspace}
                                />
                            }
                        />
                    </Switch>
                    :
                    <Switch>
                        {/* headers on unschooler */}
                        {isRedirect && <Redirect from={pathname} to={pathname + '/'} />}
                        {!user?.id && (
                            <Route
                                exact
                                path={[
                                    `${localePrefix}/`,
                                    `${localePrefix}/students/`,
                                    `${localePrefix}/schools/`,
                                    `${localePrefix}/online-courses/`,
                                    `${localePrefix}/online-courses/:alias`,
                                    `${localePrefix}/bundles/:id`,
                                    `${localePrefix}/certificate/:id`,
                                    `${localePrefix}/user-certificate/:id`,
                                    `${localePrefix}/tutorials/:by/:id`,
                                    `${localePrefix}/tutorials/:id`,
                                    `${localePrefix}/reset-password`,
                                    `${localePrefix}/email-verification`,
                                    `${localePrefix}/confirm-email`,
                                ]}
                                component={HeaderGuest}
                                isAllowCreator={isAllowCreator}
                            />
                        )}
                    </Switch>
                }

                {/* routes in workspaces, open pages */}
                {(hostname && workspace) ? (
                    <Switch location={background || location}>
                        {idRedirectLocation && (<Redirect to={localePrefix + getTrimPath()} />)}

                        <Route
                            path={`${localePrefix}/test/page`}
                            render={(props) => (
                                <TestPage {...props} />
                            )}
                        />

                        {/* available page */}
                        <Route path={`${localePrefix}/callback/calendar`} component={CallbackCalendar} />
                        {!user?.id
                            ?
                            < Route
                                exact
                                path={`${localePrefix}/invite`}
                                render={(props) =>
                                    <Invite {...props}
                                        user={user}
                                        hostname={hostname}
                                        workspace={workspace}
                                        onUpdateWorkspace={onUpdateWorkspace}
                                    />}
                            />
                            :
                            <Redirect from={`${localePrefix}/invite`} to={`${localePrefix}/`} />
                        }

                        <Route
                            exact
                            path={`${localePrefix}/profile/`}
                            render={(props) => (
                                <MyProfile {...props} user={user} loading={loading} workspace={workspace}  />
                            )}
                        />
                        <Route path={`${localePrefix}/logout`} component={Logout} />
                        <Route path={`${localePrefix}/user-certificate/:id`} render={(props) => <UserCertificate {...props} user={user} workspace={workspace} />} />
                        <Route path={`${localePrefix}/terms`} render={() => <TermsStudent workspace={workspace} />} />
                        <Route path={`${localePrefix}/policy`} render={() => <PolicyStudent workspace={workspace} />} />

                        {/* page for members without access */}
                        {showInvitePage ?
                            <Route
                                path={[
                                    `${localePrefix}/`,
                                    `${localePrefix}/invite`,
                                    `${localePrefix}/students/`,
                                    `${localePrefix}/online-courses/:alias`,
                                    `${localePrefix}/bundles/:id`,
                                    `${localePrefix}/tutorials/:by/:id`,
                                    `${localePrefix}/tutorials/:id`,
                                    `${localePrefix}/reset-password`,
                                ]}
                                render={(props) => {
                                    const { location = {} } = props || {};
                                    const isResetPassword = location?.pathname === `${localePrefix}/reset-password`;
                                    return isResetPassword ?
                                        null
                                        :
                                        <Invite {...props}
                                            user={user}
                                            hostname={hostname}
                                            workspace={workspace}
                                            onUpdateWorkspace={onUpdateWorkspace}
                                        />
                                }}
                            />
                            :
                            <>
                                {/* pages for members with access */}
                                <Route
                                    exact
                                    path={`${localePrefix}/`}
                                    render={(props) =>
                                        <Workspace {...props}
                                            user={user}
                                            hostname={hostname}
                                            workspace={workspace}
                                            onUpdateWorkspace={onUpdateWorkspace}
                                            workspaceLoading={workspaceLoading}
                                            setIsEditMode={setIsEditMode} />
                                    }
                                />
                                {/* embed in workspaces */}
                                <Route
                                    path={`${localePrefix}/embed/tutorials/:id`}
                                    render={(props) => (
                                        <Embed {...props} isEmbed='embed/' user={user} workspace={workspace} as={TaskArticlePage} />
                                    )}
                                />
                                <Route
                                    path={`${localePrefix}/embed/online-courses/:id`}
                                    render={(props) => (
                                        <Embed {...props} user={user} isEmbed='embed/' workspace={workspace} as={EventPage} />
                                    )}
                                />
                                <Route
                                    path={`${localePrefix}/embed/bundles/:id`}
                                    render={(props) => (
                                        <Embed {...props} user={user} hostname={hostname} isEmbed='embed/' workspace={workspace} as={BundlePage} />
                                    )}
                                />
                                <Route
                                    path={`${localePrefix}/embed/certificate/:id`}
                                    render={(props) => (
                                        <Embed {...props} user={user} isEmbed='embed/' workspace={workspace} as={Certificate} />
                                    )}
                                />

                                {/* regular pages on workspace */}

                                <Route
                                    path={`${localePrefix}/online-courses/:id`}
                                    render={(props) => <EventPage {...props} user={user} hostname={hostname} workspace={workspace} />}
                                />
                                <Route
                                    path={`${localePrefix}/certificate/:id`}
                                    render={(props) => <Certificate {...props} user={user} workspace={workspace} hostname={hostname} />}
                                />
                                <Route
                                    path={`${localePrefix}/bundles/:id`}
                                    render={(props) => <BundlePage {...props} user={user} hostname={hostname} workspace={workspace} />}
                                />

                                <Route
                                    path={`${localePrefix}/tutorials/:id`}
                                    render={(props) => (
                                        <TaskArticlePage {...props} user={user} hostname={hostname} workspace={workspace} />
                                    )}
                                />
                                <Route
                                    path={`${localePrefix}/edit-task/:id?`}
                                    render={(props) => <EditTaskPage {...props} user={user} workspace={workspace} />}
                                />
                                <Route
                                    path={`${localePrefix}/create-event/:id`}
                                    render={(props) => (
                                        <CreateEvent {...props}
                                            workspace={workspace}
                                            user={user}
                                            loadingUser={loading} />
                                    )}
                                />
                                <Route
                                    path={`${localePrefix}/create-bundle/:id`}
                                    render={(props) => (
                                        <CreateEvent {...props}
                                            workspace={workspace}
                                            user={user}
                                            loadingUser={loading} />
                                    )}
                                />
                                <Route
                                    path={`${localePrefix}/profile/:id`}
                                    render={(props) => (
                                        <ProfilePage {...props} user={user} loading={loading} workspace={workspace} />
                                    )}
                                />
                                {inviteStudents &&
                                    <Route
                                        path={`${localePrefix}/students`}
                                        render={(props) => (
                                            <Students
                                                {...props}
                                                user={user}
                                                loading={loading}
                                                workspace={workspace}
                                                onUpdateWorkspace={onUpdateWorkspace}
                                            />
                                        )}
                                    />
                                }

                                <Route
                                    path={`${localePrefix}/login/`}
                                    render={(props) => <Login {...props} />}
                                />

                                <Route
                                    path={`${localePrefix}/checkout/`}
                                    component={Checkout}
                                />
                                <Route path={`${localePrefix}/success/`} component={Success} />
                                <Route
                                    path={`${localePrefix}/callback/youtube`}
                                    render={() => <YoutubeCallback user={user} />}
                                />
                                <Route
                                    exact
                                    path={`/embed/video-preview/:id`}
                                    render={(props) => (<VideoCustomPlayerPreview {...props} />)}
                                />
                            </>
                        }

                        <Route
                            path={`${localePrefix}/forbidden`}
                            component={PageForbidden}
                        />
                        <Route
                            path={`${localePrefix}/access-denied`}
                            component={AccessDenied}
                        />

                        <Redirect to={`${localePrefix}/`} />
                    </Switch>
                ) : (
                    <Switch location={background || location}>
                        {/* routes ON UNSCHOOLER */}
                        {idRedirectLocation && (<Redirect to={localePrefix + getTrimPath()} />)}
                        {isRedirect && <Redirect from={pathname} to={pathname + '/'} />}

                        <Route path={`${localePrefix}/callback/calendar`} component={CallbackCalendar} />
                        <Route path={`${localePrefix}/policy/`} component={PolicyStudent} />
                        <Route path={`${localePrefix}/terms/`} component={TermsStudent} />
                        <Route path={`${localePrefix}/terms-mentor/`} component={TermsMentor} />
                        <Route path={`${localePrefix}/affiliate/`} component={Affiliate} />
                        <Route
                            path={`${localePrefix}/profile/:id`}
                            render={(props) => (
                                <ProfilePage {...props} user={user} loading={loading} />
                            )}
                        />

                        <Route
                            exact
                            path={`${localePrefix}/profile/`}
                            render={(props) => (
                                <MyProfile {...props} user={user} loading={loading} />
                            )}
                        />
                        <Route
                            exact
                            path={`${localePrefix}/schools/`}
                            render={(props) => (
                                <Workspaces {...props} user={user} />
                            )}
                        />
                        {!loading && <Route
                            exact
                            path={`${localePrefix}/`}
                            render={(props) =>
                                !user?.id ?
                                    <Landing {...props} user={user} text='' />
                                    :
                                    <Redirect from={`${localePrefix}/`} to={`${localePrefix}/schools/`} />
                            }
                        />}
                        {/* embed on Unschooler */}
                        <Route
                            path={`${localePrefix}/embed/tutorials/:id`}
                            render={(props) => (
                                <Embed {...props} isEmbed='embed/' user={user} as={TaskArticlePage} />
                            )}
                        />
                        <Route
                            path={`${localePrefix}/embed/online-courses/:id`}
                            render={(props) => (
                                <Embed {...props} user={user} isEmbed='embed/' as={EventPage} />
                            )}
                        />
                        <Route
                            path={`${localePrefix}/embed/bundles/:id`}
                            render={(props) => (
                                <Embed {...props} user={user} as={BundlePage} hostname={hostname} workspace={workspace} />
                            )}
                        />
                        <Route
                            path={`${localePrefix}/embed/certificate/:id`}
                            render={(props) => (
                                <Embed {...props} user={user} isEmbed='embed/' as={Certificate} />
                            )}
                        />
                        <Route
                            path={`${localePrefix}/embed/user-certificate/:id`}
                            render={(props) => (
                                <Embed {...props} user={user} isEmbed='embed/' as={UserCertificate} />
                            )}
                        />


                        {/* not embed */}
                        <Route
                            exact
                            path={`${localePrefix}/educators/`}
                            render={(props) => <Landing {...props} text='' />}
                        />

                        <Route
                            exact
                            path={`${localePrefix}/video/`}
                            render={(props) => <LandingVideo {...props} text='' />}
                        />
                        <Route
                            exact
                            path={`${localePrefix}/online-courses/`}
                            render={(props) => <Events {...props} user={user} />}
                        />
                        <Route
                            exact
                            path={`${localePrefix}/program/`}
                            render={(props) => <Program {...props} user={user} />}
                        />
                        <Route
                            path={`${localePrefix}/tutorials/:by/:id`}
                            render={(props) => <Community {...props} user={user} />}
                        />
                        <Route
                            exact
                            path={`${localePrefix}/skills/`}
                            render={(props) => <SkillsTree {...props} user={user} />}
                        />
                        <Route
                            exact
                            path={`${localePrefix}/careers/`}
                            render={(props) => <Careers {...props} user={user} />}
                        />
                        <Route
                            path={`${localePrefix}/payments/`}
                            render={(props) => <Payments {...props} user={user} />}
                        />
                        <Route
                            path={`${localePrefix}/credits/`}
                            render={(props) => <Credits {...props} user={user} />}
                        />
                        <Route
                            path={`${localePrefix}/login/`}
                            render={(props) => <Login {...props} user={user} />}
                        />
                        <Route path={`${localePrefix}/logout`} component={Logout} />
                        <Route
                            path={`${localePrefix}/checkout/`}
                            component={Checkout}
                        />
                        <Route path={`${localePrefix}/success/`} component={Success} />
                        <Route
                            path={`${localePrefix}/online-courses/:alias`}
                            render={(props) => <EventPage {...props} user={user} />}
                        />
                        <Route
                            path={`${localePrefix}/certificate/:id`}
                            render={(props) => <Certificate {...props} user={user} />}
                        />
                        <Route
                            path={`${localePrefix}/user-certificate/:id`}
                            render={(props) => <UserCertificate {...props} user={user} />}
                        />

                        <Route
                            path={`${localePrefix}/bundles/:id`}
                            render={(props) => <BundlePage {...props} user={user} hostname={hostname} workspace={workspace} />}
                        />

                        <Route
                            path={`${localePrefix}/edit-task/:id?`}
                            render={(props) => <EditTaskPage {...props} user={user} />}
                        />
                        <Route
                            path={`${localePrefix}/tutorials/:id`}
                            render={(props) => (
                                <TaskArticlePage {...props} user={user} />
                            )}
                        />
                        <Route
                            path={`${localePrefix}/create-event/:id`}
                            render={(props) => (
                                <CreateEvent {...props}
                                    user={user}
                                    loadingUser={loading} />
                            )}
                        />
                        <Route
                            path={`${localePrefix}/create-bundle/:id`}
                            render={(props) => (
                                <CreateEvent {...props}
                                    user={user}
                                    loadingUser={loading} />
                            )}
                        />
                        <Route
                            path={`${localePrefix}/correct-skills/`}
                            render={(props) => <CorrectSkills {...props} user={user} />}
                        />
                        <Route
                            path={`${localePrefix}/subscriptionUser`}
                            render={(props) => (
                                <SubscriptionUser {...props} user={user} />
                            )}
                        />

                        <Route
                            path={`${localePrefix}/test/page`}
                            render={(props) => (
                                <TestPage {...props} />
                            )}
                        />

                        <ProtectedRoleRoute
                            exact
                            path='/admin'
                            component={Admin}
                            user={user}
                        />
                        <ProtectedRoleRoute
                            path='/admin/promoes'
                            user={user}
                            component={Promoes}
                        />
                        <ProtectedRoleRoute
                            path='/admin/promo/create'
                            user={user}
                            component={CreatePromo}
                        />
                        <ProtectedRoleRoute
                            path='/admin/promo/:id'
                            user={user}
                            exact
                            component={Promo}
                        />

                        <ProtectedRoleRoute
                            path='/admin/payments'
                            user={user}
                            component={AdminPayments}
                        />

                        <ProtectedRoleRoute
                            path='/admin/config'
                            user={user}
                            component={Config}
                        />

                        <ProtectedRoleRoute
                            path='/admin/dev'
                            user={user}
                            component={DevContainer}
                        />

                        <ProtectedRoleRoute
                            path='/admin/workspaces'
                            user={user}
                            component={WorkspacesContainer}
                        />
                        <ProtectedRoleRoute
                            path='/admin/promocodes'
                            user={user}
                            component={Promocodes}
                        />
                        <ProtectedRoleRoute
                            path='/admin/promocode/:id'
                            user={user}
                            component={Promocode}
                        />
                        <ProtectedRoleRoute
                            path='/admin/promocode-used-by'
                            user={user}
                            component={PromocodeUsedByUsers}
                        />
                        <ProtectedRoleRoute
                            path={`/admin/guadline`}
                            user={user}
                            component={GuadlineComponent}
                        />
                        <ProtectedRoleRoute
                            path={`/admin/clean-jobs`}
                            user={user}
                            component={CleanJobsComponent}
                        />
                        <ProtectedRoleRoute
                            path={`/admin/tasks-by-order`}
                            user={user}
                            component={TasksByOrderContainer}
                        />
                        <Route
                            path={`${localePrefix}/forbidden`}
                            component={PageForbidden}
                        />
                        {/*<Route*/}
                        {/*    path={`${localePrefix}/not-found`}*/}
                        {/*    component={NotFound}*/}
                        {/*/>*/}
                        <Route path={`${localePrefix}/reports`} component={Reports} />

                        <Route
                            path={`${localePrefix}/callback/youtube`}
                            render={() => <YoutubeCallback user={user} />}
                        />

                        <Route
                            path={`${localePrefix}/access-denied`}
                            component={AccessDenied}
                        />
                        <Route
                            exact
                            path={`/embed/video-preview/:id`}
                            render={(props) => (<VideoCustomPlayerPreview {...props} />)}
                        />
                        {!isEmbed &&
                            <CookieComponent />
                        }
                        <Redirect to={`${localePrefix}/`} />
                    </Switch>
                )
                }

                <Route
                    path={`${localePrefix}/reset-password`}
                    render={(props) => (
                        <ResetPassword {...props}
                            user={user} />
                    )}
                />
                <Route
                    path={`${localePrefix}/confirm-email`}
                    render={(props) => (
                        <ConfirmEmail {...props} />
                    )}
                />
                <Route
                    path={`${localePrefix}/email-verification`}
                    render={(props) => (
                        <ConfirmEmail {...props} />
                    )}
                />
                <Route
                    path={`${localePrefix}/rating`}
                    render={(props) => (
                        <Rating {...props} />
                    )}
                />
                {background && (
                    <Route
                        exact
                        path={`${localePrefix}/tutorials/:id/edit-task/`}
                        render={(props) => (
                            <CreateTaskDrawer {...props} user={user} />
                        )}
                    />
                )}
                {(hostname && workspace) && (
                    <Switch>
                        <Route
                            exact
                            path={[
                                `${localePrefix}/`,
                                `${localePrefix}/invite`,
                                `${localePrefix}/profile/`,
                                `${localePrefix}/online-courses/:alias`,
                                `${localePrefix}/certificate/:id`,
                                `${localePrefix}/user-certificate/:id`,
                                `${localePrefix}/terms`,
                                `${localePrefix}/policy`,
                                `${localePrefix}/bundles/:alias`,
                            ]}
                            render={() => <FooterContainer workspace={workspace}
                                isEmbed={isEmbed}
                                isEditMode={isEditMode} />}
                        />
                    </Switch>
                )}
            </main>
            <ThemeSwitcherAutoComponent />
        </>
    );
};
