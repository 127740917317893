import React from 'react';
import { YouTubeInput } from '../../common';

export const YouTubeLinkTab = ({ task, setCover, ...other }) => {
    const getCover = (urlName, sourceText) => {
        if (urlName) {
            return {
                type: "YOUTUBE",
                source: urlName,
                id: task.cover?.id,
                raw: sourceText || '',
                timestamps: [],
                title: 'urlName',
            }
        }
    }

    const onVideoDataChange = ({  urlName, sourceText }) => {
        const newCoverData = getCover( urlName, sourceText );
        setCover(newCoverData);
    };

    return (
        <YouTubeInput
            onVideoDataChange={onVideoDataChange}
            task={task}
            {...other}
        />
    );
};