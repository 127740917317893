import React, {useContext} from 'react';
import {AddCustomDomainComponent} from '../AddCustomDomain.component';
import {useAddCustomDomain, useDeleteCustomDomain} from '../hook';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';
import {isDevelopment} from '../../common/utils';

const baseHost = isDevelopment ? 'localhost:3000' : 'unschooler.me';

export const AddCustomDomainContainer = ({customDomain}) => {
    const {workspace} = useContext(FirebaseContext);
    const {id, state, customDomain: savedDomain, host} = workspace || {};
    const {addCustomDomain, loading: addLoading, data: addData} = useAddCustomDomain();
    const {deleteCustomDomain, loading: deleteLoading, data: deleteData} = useDeleteCustomDomain();

    const handleAddDomain = async () => await addCustomDomain(customDomain, id);
    const handleDeleteDomain = async () => {
        await deleteCustomDomain(customDomain, id);
        window.location.href = `${window.location.protocol}//${host}.${baseHost}`;
    };

    return <AddCustomDomainComponent addLoading={addLoading}
                                     deleteLoading={deleteLoading}
                                     isAddDomainMode={!savedDomain}
                                     isVerified={state}
                                     addData={addData?.addCustomDomain}
                                     isDelete={!!deleteData}
                                     onAddDomain={handleAddDomain}
                                     onDeleteDomain={handleDeleteDomain}/>
}