import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Button, Input, Image } from 'antd';
import { LoaderComponent, UploadImgComponent } from '../../common';
import { CertificateComponent } from '../Certificate.component/Certificate.component';

const { TextArea } = Input;

export const CertificateFormComponent = (props) => {
    const {
        certificate,
        loading,
        removeLoading,
        onSend,
        setSertificate,
        onRemove,
        setSelectedImage,
        selectedImage,
        event
    } = props || {};

    const { id, title, description, label, icon, type = 'BADGE' } = certificate || {};
    const { formatMessage } = useIntl();
    const { useForm } = Form;
    const [form] = useForm();
    const [isAdd, setIsAdd] = useState();

    const handleSelectImage = (url) => {
        setSelectedImage(url);
        form.setFieldsValue({ icon: url });
        setSertificate({ ...form.getFieldsValue(), ...(id && { id }) });
    };

    const predefinedImages = Array.from({ length: 16 }, (v, i) => ({
        id: i + 1,
        url: `https://cdn.unschooler.me/web_app/achievement/cert/cert_${i + 1}.png`
    }));

    // TODO: Need translations

    return (
        <div className="certificate-form-component">
            {(!certificate && !isAdd) && (
                <Button type="primary" onClick={async () => {
                    await setIsAdd(true);
                    await setSertificate({ ...form.getFieldsValue() });
                }}>
                    Add certificate
                </Button>
            )}

            {(!!certificate || isAdd) && (
                <div>
                    <Form
                        name='create-certificate'
                        layout='vertical'
                        form={form}
                        initialValues={
                            {
                                title: title || formatMessage({ id: 'certificate_title' }),
                                description: description || formatMessage({ id: 'certificate_description' }),
                                label: label || formatMessage({ id: 'certificate_label' }),
                                icon: icon || 'https://cdn.unschooler.me/web_app/achievement/cert/cert_1.png'
                            }
                        }
                        onFinish={onSend}
                        onValuesChange={(_, allValues) => {
                            setSertificate({ ...allValues, ...(id && { id }) });
                        }}
                    >
                        <Form.Item name="title"
                            label="Certificate name"
                            rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="description"
                            label="Certificate description"
                            rules={[{ required: true }]}>
                            <TextArea autoSize={{
                                minRows: 3,
                                maxRows: 4,
                            }}
                                maxLength={300}
                                showCount
                            />
                        </Form.Item>
                        <Form.Item name="label"
                            label="Certificate label"
                            rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='icon'
                            label="Certificate image"
                            rules={[{ required: true }]}>
                            <div className="image-gallery">
                                {predefinedImages.map((img) => (
                                    <Image
                                        key={img.id}
                                        src={img.url}
                                        width={100}
                                        style={{ margin: '0 8px 8px 0', border: selectedImage === img.url ? '2px solid blue' : 'none' }}
                                        onClick={() => {
                                            handleSelectImage(img.url)
                                        }}
                                        preview={false}
                                    />
                                ))}
                            </div>
                            <UploadImgComponent
                                onSetImages={(data) => {
                                    const { url } = data?.[0]?.response || {};
                                    if (url) {
                                        handleSelectImage(url);
                                    }
                                }}
                                onRemove={() => {
                                    form.setFieldsValue({ icon: '' });
                                    setSelectedImage('');
                                }}
                                limitImages='1'
                                savedImages={selectedImage ? [{ url: selectedImage, thumbUrl: selectedImage }] : []}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type='tertiary'
                                disabled={loading}
                                htmlType='submit'>
                                <span>
                                    {loading && (<LoaderComponent type="inline" />)}
                                    Save certificate
                                </span>
                            </Button>
                        </Form.Item>
                        {certificate && (
                            <Button type="link"
                                disabled={removeLoading}
                                onClick={onRemove}>
                                <span>
                                    {removeLoading && (<LoaderComponent type="inline" />)}
                                    Remove certificate
                                </span>
                            </Button>
                        )}
                    </Form>
                    <CertificateComponent
                        certificate={{
                            ...(form.getFieldsValue()?.title ? form.getFieldsValue() : certificate),
                            event: event,
                            isAppliedForCurrentUser: true,
                        }}
                    />
                </div>
            )}

            <div className='wrapper_next-step mt-m-l_m'>
                <div></div>
                <div className='next-step_btns'>
                    <Form.Item>
                        <Button
                            type='tertiary'
                            onClick={() => onSend(form.getFieldsValue(), true)}
                        >
                            {formatMessage({ id: 'create_event_save' })}
                        </Button>
                    </Form.Item>
                </div>
            </div>
        </div>
    )
}
