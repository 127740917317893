import { isArray } from 'lodash';
import { database } from '../../model/firebase';

export const fetchData = (path) => {
    return database.ref(path).once('value');
};

export const fetchUsers = async () => {
    let users = await database.ref('users').once('value');
    return users;
};

export const fetchUserProfile = async (uid) => {
    let result;
    let userProfile = await database
        .ref('users/' + uid)
        .once('value', (snap) => {
            result = snap.val();
        })
        .then(() => {
            return result;
        });

    return userProfile;
    //  такого пользователя не существует
};

// data record
export const setRootData = (dbPropertyKey, data) => {
    return database.ref(dbPropertyKey).set(data);
};

export const setData = (dbPropertyKey, id, data) => {
    return database.ref(`${dbPropertyKey}/${id}/${data.prop}`).set(data);
};

export const setDataArray = (dbPropertyKey, id, data) => {
    return database.ref(`${dbPropertyKey}/${id}`).set(data);
};
export const setDataByPath = (dbPropertyKey, id, path, data) => {
    return database.ref(`${dbPropertyKey}/${id}/${path}`).set(data);
};

export const setKey = async (dbPropertyKey) => {
    let snapshot = await database.ref(dbPropertyKey).push();
    return snapshot.key;
};

export const getWorkspacesByOwnerId = async (ownerId, dbPropertyKey) => {
    return await database.ref(dbPropertyKey).orderByChild('ownerId').equalTo(ownerId).once('value')
        .then(function (snapshot) {
            var objects = snapshot.val();
            return objects;
        })
        .catch(function (error) {
            console.error('Error: ' + error.code);
        });
}

const USER_EVENTS = 'events-user';
export const setUserEvents = async (user, event) => {
    const dbPath = `${USER_EVENTS}/${user}`;
    const dbData = await fetchData(dbPath);

    let data = dbData.val() || [];
    data.push(event);
    await setData(USER_EVENTS, user, data);
};

export const setParticipate = async (
    user,
    activeEvent,
    date,
    paymetType,
    studentData,
    isSubscription
) => {
    let data = {
        participantId: user.googleUid,
        participantEmail: user.email,
        participantName: studentData?.studentName || user.name,
        participantImg: user.picture,
        times: [],
    };

    const livePeriod = 3 * 60 * 1000;

    if (isSubscription) {
        const d = new Date(date.dateUnix);
        const lastDate = new Date(d.setMonth(d.getMonth() + 1)).valueOf();
        data.times.push({
            date_start: Date.now(),
            date_end: lastDate + livePeriod,
        });
        data.isSubscriber = true;
    } else {
        if (date) {
            data.times.push({
                date_start: Date.now(),
                date_end: date.dateUnix + livePeriod,
            });
        }
    }

    if (studentData?.level) {
        data.level = studentData?.level;
    }

    if (paymetType) {
        data.paymetType = paymetType;
    }

    if (paymetType === 'paid') {
        data.isPaid = true;
    }

    await setDataByPath(
        'events',
        activeEvent,
        `participants/${user.googleUid}`,
        data
    );
    await setUserEvents(user.id, activeEvent);
};

export const recordLikerList = async (task, user) => {
    const likerData = {
        name: user.name,
        picture: user.picture,
        uid: user.googleUid,
    };

    await database
        .ref()
        .child(`/tasks/${task.id}/likerList/${user.googleUid}`)
        .set(likerData);
    await database
        .ref()
        .child(`/users/${user.googleUid}/answersLiked/${task.id}`)
        .set(task.name);
};

export const recordWeeklyLikes = async (taskId, googleUid, currentDate) => {
    const likeData = {
        createDate: currentDate,
    };

    await database
        .ref()
        .child(`/weeklyLikes/${taskId}/${googleUid}`)
        .set(likeData);
};

export const recordAnswer = async (answerData) => {
    await database.ref('/tasks/' + answerData.id).update(answerData);
    await database
        .ref(
            '/users/' + answerData.creator.uid + '/answersList/' + answerData.id
        )
        .set(answerData.id);
};

export const recordFavoriteTheme = async (theme, uid) => {
    await database
        .ref()
        .child(`/users/${uid}/themesStates/${theme.id}`)
        .set('studied');
};

export const recordQuestions = async (taskId, questionId, optionId) => {
    const dbData = await database
        .ref()
        .child(`/questionsCount/${taskId}/${questionId}/${optionId}`)
        .once('value');

    let data = dbData.val();

    if (data) {
        await database
            .ref()
            .child(`/questionsCount/${taskId}/${questionId}/${optionId}`)
            .set(data + 1);
    } else {
        await database
            .ref()
            .child(`/questionsCount/${taskId}/${questionId}/${optionId}`)
            .set(1);
    }
};

export const recordMonthlyCourses = async (userId, month, count) => {
    await database
        .ref()
        .child(`/coursesCount/${userId}/${month}/`)
        .set(count);
};

export const recordCurrentCredits = async (userId, count) => {
    await database
        .ref()
        .child(`/currentCredits/${userId}/currentCredits`)
        .set(count);
};

export const reportBrokenFile = async (audioUrl, userId, entityId) => {
    const data = { entityId: entityId, audioUrl: audioUrl }
    let id = new Date();
    await database
        .ref()
        .child(`/currentCredits/${userId}/report/${id}`)
        .set(data);
};



//coins add
export const recordCoins = async (googleUid, coinsNumber, actionId, user) => {

    const dbData = await database
        .ref()
        .child(`/coins/${googleUid}`)
        .once('value');
    const actionsList = isArray(actionId) ? actionId : [actionId];

    if (dbData.val()) {
        let data = { ...dbData.val() };
        data.counter = data?.counter + coinsNumber;
        data.name = user?.name;
        data.picture = user?.picture;
        data.uId = user?.id;

        if (actionId) {
            data.actionId = data.actionId
                ? [...data.actionId, ...actionsList]
                : actionsList;
        }

        !dbData.val().actionId?.includes(actionId) &&
            (await database.ref().child(`/coins/${googleUid}`).set(data));
    } else {
        let data = {};
        data.counter = coinsNumber;
        data.name = user?.name;
        data.picture = user?.picture;
        data.uId = user?.id;

        if (actionId) data.actionId = actionsList;
        await database.ref().child(`/coins/${googleUid}`).set(data);
    }
};

//remove
export const removeCoins = async (googleUid, coinsNumber, actionId) => {
    await database
        .ref()
        .child(`/coins/${googleUid}`)
        .once('value', (snap) => {
            if (snap.val()) {
                let data = snap.val();

                if (data?.counter > coinsNumber) {
                    data.counter = data?.counter - coinsNumber;

                    if (!snap.val()?.actionId.includes(actionId)) {
                        if (actionId) {
                            data.actionId = data.actionId
                                ? [...data.actionId, actionId]
                                : [actionId];
                        }
                        database.ref().child(`/coins/${googleUid}`).set(data);
                    }
                }
            } else {
                return null;
            }
        });
};

export const removeByPath = async (dbPath) => {
    await database
        .ref()
        .child(dbPath)
        .remove();
};

export const getDatabaseRef = () => {
    return (ref = '') => database.ref(ref);
};
