import './scss/index.scss';
import React, { useState, useEffect } from 'react';
import { Tabs, Button, Modal, message } from 'antd';
import { useIntl } from 'react-intl';
import { noop } from 'lodash';

import { AIVideoTab, YouTubePublicTab, YouTubeLinkTab, MP4UploadTab, VimeoTab } from './index';
import { useEditTask } from '../../task/hooks/useEditTask';
const { TabPane } = Tabs;

export const ChangeVideoContainer = ({
    task,
    user,
    workspace,
    userIsAllowGenerateContent,
    editOthersCourses,
    updateTask,
    updateEvent = noop,
    ...other
}) => {
    const { editTask } = useEditTask();
    const [activeTab, setActiveTab] = useState('1');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inProgressVideo, setInProgressVideo] = useState(false)
    const [cover, setCover] = useState()

    const successMessage = (content) => {
        message.success(`New ${content} was saved`, 3);
    };
    const errorMessage = () => {
        message.error('Ai did not respond, reload the page', 3);
    };
    const getCleanCover = (coverData) => {
        const { type, source, title, raw, timestamps, } = coverData;
        return { type, source, title, raw, timestamps};
    }

    const handleEditVideoTask = async () => {
        let tagIdsArray = [];
        task?.tags?.forEach((tag) => tagIdsArray.push(tag.id));

        const updatedTask = await editTask({
            variables: {
                taskId: task?.id,
                taskData: {
                    tagIds: tagIdsArray,
                    cover: cover ? getCleanCover(cover) : null,
                    ...(cover?.raw && { source: `${task.source} ${cover.raw}` } ),
                },

                isOwner: editOthersCourses,
            },
        });

        successMessage('video');
        updateEvent(updatedTask)
        setIsModalVisible(false)
        if (updateTask) {
            await updateTask({ fetchPolicy: "network-only" });
        }
    };

    const { formatMessage } = useIntl();
    const handleTabChange = (key) => {
        setActiveTab(key);
    };


    return (
        <div >
            <Button
                type="secondary"
                size='small '
                block={true}
                onClick={() => { setIsModalVisible(true); }}
            >
                {formatMessage({ id: 'regenerate_video_button' })}
            </Button>
            
            <Modal
                title={formatMessage({ id: 'regenerate_video_button' })}
                visible={isModalVisible}
                onOk={() => {
                    if (cover) { handleEditVideoTask(); }
                    setIsModalVisible(false);
                }}
                okText={formatMessage({ id: 'save_button' })}
                cancelText={formatMessage({ id: 'cancel_label' })}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                width={800}
                style={{ top: 30 }}
                className='modal_video'
            >
                <Tabs activeKey={activeTab} onChange={handleTabChange}>
                    <TabPane tab="AI Video" key="1">
                        <AIVideoTab
                            setCover={setCover}
                            task={task}
                            user={user}
                            workspace={workspace}
                            userIsAllowGenerateContent={userIsAllowGenerateContent}
                            setIsModalVisible={setIsModalVisible}
                            {...other}
                        />
                    </TabPane>
                    <TabPane tab="YouTube Public" key="2">
                        <YouTubePublicTab
                            inProgress={inProgressVideo}
                            setInProgress={setInProgressVideo}
                            setCover={setCover}
                            task={task}
                            user={user}
                            workspace={workspace}
                            userIsAllowGenerateContent={userIsAllowGenerateContent}
                            {...other}
                        />
                    </TabPane>
                    <TabPane tab="YouTube Link" key="3">
                        <YouTubeLinkTab
                            inProgress={inProgressVideo}
                            setInProgress={setInProgressVideo}
                            setCover={setCover}
                            task={task}
                            user={user}
                            workspace={workspace}
                            userIsAllowGenerateContent={userIsAllowGenerateContent}
                        />

                    </TabPane>
                    <TabPane tab="MP4 Upload" key="4">
                        <MP4UploadTab
                            inProgress={inProgressVideo}
                            setInProgress={setInProgressVideo}
                            setCover={setCover}
                            task={task}
                            user={user}
                            workspace={workspace}
                            userIsAllowGenerateContent={userIsAllowGenerateContent}
                        />
                    </TabPane>
                    <TabPane tab="Vimeo" key="5">
                        <VimeoTab
                            inProgress={inProgressVideo}
                            setInProgress={setInProgressVideo}
                            setCover={setCover}
                            task={task}
                            user={user}
                            workspace={workspace}
                            userIsAllowGenerateContent={userIsAllowGenerateContent}
                        />
                    </TabPane>
                </Tabs>
            </Modal>
        </div>
    );
};