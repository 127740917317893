import './scss/index.scss';

import React, {useEffect, useState, useRef, useCallback} from 'react';
import classnames from 'classnames';
import {Form} from 'antd';

// TODO: Youtube review
// import {YoutubeCaptionsContainer, useGetVideoCaptions} from '../../youtube';
import {useGetSummary, useGetCommonAi, useGetExplainVideo} from '../../ai/hook';

export const VideoIframe = ({
    timestampsList,
    handleEditVideoTask,
    handleChangeVideo,
    setInProgress,
    inProgress,
    videoSrc,
    setVideoSrc,
    videoSet,
    task,
    user,
    showCaptions,
    ...other
}) => {
    // it seems this is outdaged component
    const playerRef = useRef();
    // TODO: Youtube review
    // const {getVideoCaptions} = useGetVideoCaptions();
    const [captions, setCaptions] = useState();
    const {getSummary, error} = useGetSummary();
    const {getCommonAi, errorAi} = useGetCommonAi();
    const {getExplainVideo} = useGetExplainVideo();
    const [timestamps, setTimestamps] = useState(timestampsList);
    const [value, setValue] = useState();
    const [isVisible, setIsVisible] = useState();
    const [inProgressVideo, setInProgressVideo] = useState();
    const {useForm} = Form;
    const [form] = useForm();
    const {getFieldsValue, setFieldsValue} = form;

    const handleChangeForm = (e) => {
        const val = e.target.value;
        setValue(val);
        setIsVisible(!!val);
    };
    const handleFocus = (e) => {
        const keyCode = e.keyCode || e.which;
        const isSubmit = !e.shiftKey && keyCode === 13;
        if (!inProgress && isSubmit) {
            const formData = getFieldsValue();
            handleAnswerTimeStamp(formData);
        }
    };

    // TODO: Youtube review
    // const handleGetCaptions = async (id) => {
    //     if (task?.cover?.source) {
    //         const resp = await getVideoCaptions({
    //             variables: {
    //                 id: task.cover.source,
    //             },
    //         });
    //
    //         setCaptions(resp?.data?.getVideoCaptions?.captions);
    //     }
    // };
    const handleTimeStampClick = (timestamp) => {};
    const handleSaveNewVideo = async () => {
        setInProgressVideo(true);
        let coverData = {
            cover: {
                type: 'YOUTUBE',
                source: videoSrc,
                id: task.cover.id,
            },
        };

        user?.id === task?.creator?.id && handleEditVideoTask(coverData);
        setInProgressVideo(false);
    };
    const getTime = () => {
        const playerInfo = window.YtPlayer?.playerInfo || {};
        const {currentTime} = playerInfo;

        let time = Math.floor(currentTime * 1000);
        return time;
    };
    const handleAnswerTimeStamp = useCallback(
        async (e) => {
            setInProgressVideo(true);
            setFieldsValue('');
            // trackClick(
            //     `bookmark${'-example'}`,
            //     'example',
            //     `https://unschooler.me/`
            // );

            let time = getTime();
            let nextElementId =
                captions?.findIndex((el) => el.offset > time) || 1;

            let timeString = captions[nextElementId - 1]?.offset
                ? captions[nextElementId - 1]?.offset
                : 0;
            let stringArray;
            if (nextElementId < 5 || !nextElementId) {
                stringArray = captions.slice(0, 10);
            } else {
                stringArray = captions.slice(
                    nextElementId - 5,
                    nextElementId + 5
                );
            }
            let stringContext = stringArray.map((el) => el?.text).join(' ');
            let title = window.YtPlayer?.videoTitle;

            const responseTrimmed = await getExplainVideo(
                stringContext + 'Transcript from ' + title,
                e.ask.trim(),
                captions[nextElementId - 1]?.text
            );
            if (responseTrimmed) {
                const captionIndex =
                    nextElementId - 1 >= 0 ? nextElementId - 1 : 0;
                let newTimeStamp = {
                    timestamp: `${timeString}`,
                    title:
                        e.ask.trim() +
                            ': ' +
                            captions[captionIndex]?.text.substring(0, 15) +
                            '…' || 'Explain',
                    description: responseTrimmed,
                };

                let timeStampsForSave = [...timestamps, newTimeStamp];
                setTimestamps(timeStampsForSave);

                let coverData = {
                    cover: {
                        type: 'YOUTUBE',
                        source: task.cover.source,
                        timestamps: timeStampsForSave,
                        id: task.cover.id,
                    },
                };

                user?.id === task?.creator?.id &&
                    handleEditVideoTask(coverData);
                setInProgressVideo(false);
            }
        },
        [timestamps, playerRef.current, captions]
    );
    const handleGetTimeStamp = useCallback(async () => {
        setInProgressVideo(true);
        let time = getTime();
        let nextElementId = captions?.findIndex((el) => el.offset > time) || 1;

        let timeString = captions[nextElementId - 1]?.offset
            ? captions[nextElementId - 1]?.offset
            : 0;

        let stringRequest = '';
        let title = window.YtPlayer?.videoTitle;
        if (time) {
            let stringArray;

            if (nextElementId < 5 || !nextElementId) {
                stringArray = captions.slice(0, 10);
            } else {
                stringArray = captions.slice(
                    nextElementId - 2,
                    nextElementId + 10
                );
            }

            stringRequest = stringArray
                .map((el) => el?.text)
                .join(' ')
                .trim();
        } else {
            let stringArray = captions.slice(0, 30);
            stringRequest = `Title: ${title}. TransScript (can contain grammar errors): ${stringArray
                .map((el) => el?.text)
                .join(' ')}`;
        }

        const responseTrimmed = await getSummary(stringRequest);
        const titleTrimmed = await getCommonAi(stringRequest, 'title');

        if (responseTrimmed) {
            const captionIndex = nextElementId - 1 >= 0 ? nextElementId - 1 : 0;
            let newTimeStamp = {
                timestamp: `${timeString}`,
                title:
                    titleTrimmed.substring(0, 35) ||
                    captions[captionIndex]?.text?.substring(0, 15) + '…' ||
                    'Summary',
                description: responseTrimmed,
            };

            let timeStampsForSave = [...timestamps, newTimeStamp];
            setTimestamps(timeStampsForSave);

            let coverData = {
                cover: {
                    type: 'YOUTUBE',
                    source: videoSrc,
                    timestamps: timeStampsForSave,
                    id: task.cover.id,
                },
            };

            user?.id === task?.creator?.id && handleEditVideoTask(coverData);
            setInProgressVideo(false);
        }
    }, [timestamps, playerRef.current, captions]);

    const handleDeleteTimestamp = useCallback(
        async (e, timeStamp) => {
            e.preventDefault();

            if (timeStamp?.id) {
                let timeStampsDelete = timestamps.findIndex(
                    (el) => el.id === timeStamp.id
                );
                //delete from timestamps element with timeStamp.id
                let timeStampsForSave = [...timestamps];

                timeStampsForSave.splice(timeStampsDelete, 1);
                setTimestamps(timeStampsForSave);

                let coverData = {
                    cover: {
                        type: 'YOUTUBE',
                        source:  task.cover.source,
                        timestamps: timeStampsForSave,
                        id: task.cover.id,
                    },
                };

                user?.id === task?.creator?.id &&
                    handleEditVideoTask(coverData);
            }
        },
        [timestamps]
    );

    // TODO: Youtube review
    // useEffect(() => {
    //     (async () => await handleGetCaptions())();
    // }, [task?.cover?.source]);

    const videoClassNames = classnames('videoContainerStyle', {});

    return (
        <div className={videoClassNames}>
            {videoSrc && (
                <div className='playerContainerStyle'>
                    <iframe
                        id='video-iframe'
                        title={videoSrc}
                        frameBorder='0'
                        allow='autoplay; fullscreen'
                        allowFullScreen={true}
                        className='videoIframeStyle'
                        src={videoSrc}
                    />
                </div>
            )}

            { /* TODO: Youtube review */}
            {/*{showCaptions && (*/}
            {/*    <YoutubeCaptionsContainer*/}
            {/*        timestampsList={timestamps}*/}
            {/*        handleTimeStampClick={handleTimeStampClick}*/}
            {/*        handleGetTimeStamp={handleGetTimeStamp}*/}
            {/*        playerRef={playerRef}*/}
            {/*        handleChangeVideo={handleChangeVideo}*/}
            {/*        inProgressVideo={inProgressVideo}*/}
            {/*        handleFocus={handleFocus}*/}
            {/*        handleChange={handleChangeForm}*/}
            {/*        handleRequests={handleAnswerTimeStamp}*/}
            {/*        handleDeleteTimestamp={handleDeleteTimestamp}*/}
            {/*        form={form}*/}
            {/*        value={value}*/}
            {/*        isVisible={captions || isVisible}*/}
            {/*        handleSaveNewVideo={handleSaveNewVideo}*/}
            {/*        videoSet={videoSet}*/}
            {/*        videoSrc={videoSrc}*/}
            {/*        task={task}*/}
            {/*        user={user}*/}
            {/*        {...other}*/}
            {/*    />*/}
            {/*)}*/}
        </div>
    );
};
