import React, { useEffect, useState, useCallback } from 'react';
import { noop } from 'lodash';

import { EventsListComponent } from '../../events';
import { EventsListActionsContainer } from '../EventsListActions.container';
import { useAnalytics, useIsAllow } from '../../common';
import { useGetEvents, useGetEventsWithStudents, useGetEventsCounter } from '../hooks';
import { useIsPermit } from '../../roles/hook/useCheckPermission';

export const PaginatedListEventsContainer = (props) => {
    const {
        as: Component = EventsListComponent,
        filterData,
        index,
        getWorkspaceData,
        eventsLists,
        workspace,
        typeProp,
        limit = 20,
        createCourses,
        setAllEvents= noop,
        ...other
    } = props;
    const { eventTrack } = useAnalytics();
    const { getEvents, loading: loadingEvent } = useGetEvents();
    const {getEventsCounter, eventsCounter, loading: loadingEventsCounter} = useGetEventsCounter();
    const { getEventsWithStudents, loading: loadingStudents } = useGetEventsWithStudents();
    const [events, setEvents] = useState([]);
    const [type, setType] = useState(typeProp);
    const useIsAllowed = useIsAllow();
    const { checkPermit } = useIsPermit()
    const editOthersCourses = useIsAllowed("PRO") || checkPermit(workspace, 'editOthersCourses')

    const handleEventClick = (e, event) => {
        const eventData = e;
        eventData.clickText = event.title;
        eventData.clickId = 'click-event-card';
        eventData.clickUrl = `${window.href}online-courses/${event.id}`;
        eventTrack('click-event-card', eventData);
    };

    const handleChangePage = async (index) => {
        const eventsList = await handleEvents(
            filterData,
            limit * (index - 1)
        );
        setAllEvents((prev) => [...(prev || []), ...(eventsList || [])]);
        setEvents([...eventsList]);
    };

    const getCounter = useCallback(async () => {
        const { tagsIds = [], ...rest } = filterData;
        await getEventsCounter({
            variables: {
                filter: {
                    ...rest,
                    ...(!!tagsIds.length && { tags: tagsIds.map((id) => parseInt(id)) })
                }
            }
        });
    }, [filterData, getEventsCounter])

    const handleEvents = useCallback(
        async (filter, offset = 0) => {
            const { tagsIds = [], ...rest } = filter;
            if (!!createCourses) {
                const resp = await getEventsWithStudents({
                    variables: {
                        filter: {
                            ...rest,
                            ...(!!tagsIds.length && { tags: tagsIds.map((id) => parseInt(id)) })
                        },
                        isOwner: !!editOthersCourses,
                        limit,
                        offset
                    },
                    fetchPolicy: "network-only"
                });
                setAllEvents((prev) => prev ? [...prev, ...(resp?.data?.events || [])] : [...(resp?.data?.events || [])]);
                return resp?.data?.events || [];
            } else {
                const resp = await getEvents({
                    variables: {
                        filter: {
                            ...rest,
                            ...(!!tagsIds.length && { tags: tagsIds.map((id) => parseInt(id)) })

                        },
                        isOwner: !!editOthersCourses,
                        limit,
                        offset
                    },
                    fetchPolicy: "network-only"
                });
                setAllEvents((prev) => [...(prev || []), ...(resp?.data?.events || [])]);
                return resp?.data?.events || [];
            }
        },
        [filterData]
    );

    const actionsComponent = createCourses ? () => (<EventsListActionsContainer index={index}
        workspaceId={filterData.workspaceId}
        eventsLists={eventsLists}
        updateWorkspace={getWorkspaceData}
        type={type}
        setType={setType}
    />) : null;

    useEffect(() => {
        (async () => await getCounter())();
        (async () => {
            const eventsList = await handleEvents(filterData);
            if (eventsList) {
                setEvents(() => [...eventsList]);
            }
        })();
    }, []);

    return (
        <Component {...other}
            isLoading={loadingEvent || loadingStudents || loadingEventsCounter}
            events={events}
            onClick={handleEventClick}
            asActions={actionsComponent}
            filterData={filterData}
            type={type}
            createCourses={createCourses}
            setEvents={setEvents}
            workspace={workspace}
            total={eventsCounter}
            limit={limit}
            onChangePage={handleChangePage}
        />
    )
}
