import "./scss/index.scss";
import React from "react";

export const LoaderOnboardingComponent = (props) => {
    const { type = "full", label, color, title } = props;
    return (
        <>
            <div className="loader-component__header">
                <div className=" wrapper_progress_thin">
                    {/* <small className='progress_label'>
                        AI generates your lesson, wait for 1-2 min…
                    </small> */}
                    <p className="loader-component__label wrapper_inner-section">{label}</p>
                    <div className="progress-indicator progress-indicator_gray"
                        style={{
                            backgroundColor: color ? color : "#fb1c1c2b",
                        }}
                    />
                </div>

            </div>
            {type === 'overlay' &&
                <div className={`loader-component loader-component_${type}`}>
                    <div className="wrapper_content">
                        {/* ✦ */}
                        {/* <h2 >
                        Focus on system
                    </h2>
                    <p className="wrapper_inner-section">
                        <strong>Winners and losers have the same goals.</strong>
                        The difference is that winners make continuous  <strong>small improvements every day</strong>, which help them achieve results.
                    </p> */}
                        {/* <p className="wrapper_inner-section">
                        Your results depend on daily tasks.
                        Decide today <strong>what kind of professional you are</strong>, give yourself permission to be a better person, and <strong>do the next daily step </strong>that will shape up your career.
                    </p>
                  */}
                        <h2>{title}</h2>
                    </div>
                </div>
            }
        </>
    );
}
