import React, { useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Button, Input } from 'antd';
import classnames from 'classnames';
import { useIntl } from 'react-intl'
import { TaskInfoComponent } from '..';
import { Question } from "./TaskQuestionDnd"
import { Svg } from '../../common'
import { noop } from 'lodash';

export const TaskDnD = ({
    task,
    tasks,
    moveLesson,
    handleMoveUpTask,
    activeId,
    showTaskView,
    handleAddQuestion,
    handleAddTask,
    handleUnlink = noop,
    index,
    setEvent,
    onUpdateEvent,
    setActiveTask = noop,
    event,
    ...other }) => {
    const { formatMessage } = useIntl();
    const [showStepInput, setShowStepInput] = useState(false);
    const inputRef = useRef()
    const [inputValue, setInputValue] = useState(''); 

    const getTaskClasses = (task) => {
        const TaskContainer = classnames('Answer_container answer-component task_dnd_component', {
            'card-active': activeId && (activeId === task?.id || task?.questions?.find(q => q.id === activeId)),
        });
        return TaskContainer;
    };

    return (
        <>
            <Droppable droppableId={task?.title}>
                {(provided) => {
                    return (
                        <div ref={provided.innerRef} {...provided.droppableProps} className='task_dnd_component_wrapper'>
                            <div className={`${getTaskClasses(task)}`}
                                // style={{ backgroundColor: task?.id && activeId === task?.id && (task?.tags?.[0]?.subject?.color || '#999999') + '1c', }} 
                                >
                                <div className='task-component_header'>
                                    <div>
                                        <Button
                                            type="text"
                                            size="small"
                                            className="btn-left link-btn_light btn_up"
                                            onClick={() => handleMoveUpTask(task)}
                                        >↑</Button>
                                    </div>
                                    {task?.id ?
                                        <Button
                                            type='link'
                                            className='task-component__info task-component__info_dnd answer-component_card btn-left'
                                            onClick={() => showTaskView(task)}
                                            style={{ color: (task?.tags?.[0]?.subject?.color || '#999999') + '1c', }}

                                        >
                                            <TaskInfoComponent
                                                task={task}
                                                actionHidden={false}
                                                isEdit={true}
                                                {...other}
                                            />
                                        </Button>
                                        :
                                        <TaskInfoComponent
                                            task={task}
                                            actionHidden={false}
                                            isEdit={true}
                                            {...other}
                                        />
                                    }

                                    <Button
                                        type="text"
                                        size="small"
                                        className="btn-left link-btn_light btn_up show-on-hover"
                                        onClick={() => {
                                            if (event.tasks) {
                                                const newTasks = [
                                                    ...event.tasks.slice(0, index),
                                                    ...event.tasks.slice(index + 1)
                                                ];

                                                const newEvent = {
                                                    ...event,
                                                    tasks: newTasks
                                                };

                                                setEvent(newEvent);

                                                if (event.id && newTasks.length > 0) {
                                                    setActiveTask(newTasks[index - 1]);
                                                }
                                            }
                                        }
                                        }
                                    >
                                        <Svg name=':icon/cross' className='icon_check icon_16' />
                                    </Button>

                                </div>

                                <div className='answer-component_questions'>
                                    {task?.questions?.map((question, index) => (
                                        <Question
                                            key={question.id || question.title}
                                            question={question}
                                            index={index}
                                            showTaskView={showTaskView}
                                            task={task}
                                            activeId={activeId}
                                            {...other}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </div>

                                {showStepInput ? (
                                     <div >
                                        <Input
                                            ref={inputRef}
                                            value={inputValue} // Set the value of the input
                                            onChange={(e) => setInputValue(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleAddQuestion(task, inputValue);
                                                    setInputValue('');
                                                    setShowStepInput(false);
                                                }
                                            }}
                                        />
                                        <Button
                                            type='text'
                                            size='small'
                                            className="btn-left link-btn_light"
                                            onClick={() => {
                                                handleAddQuestion(task, inputValue);
                                                setInputValue('');
                                                setShowStepInput(false)
                                            }}
                                        >
                                            {formatMessage({ id: 'save_button' })}
                                        </Button>

                                    </div>
                                ) :
                                    <Button type='text'
                                        size='small'
                                        className="btn-left link-btn_light"
                                        onClick={() => setShowStepInput(true)}
                                        disabled={task?.questions?.length > 9}
                                    >
                                        {task?.questions?.length > 9 ? 'Step limit, create a course instead' : '+ ' + formatMessage({ id: 'form_add_question' })}
                                    </Button>
                                }
                            </div>
                        </div>
                    )
                }}
            </Droppable >

        </>

    );
};

