import "./scss/index.scss";
import React from "react";
import { useIntl } from "react-intl";
import { Form, Input } from 'antd';
import { WysiwygComponent , VideoGenerator} from '../../common';

export const EditTaskDescription = ({
    user,
    formTask,
    form,
    onChangeContent,
    formInitialValue,
    ...other
}) => {
    const { formatMessage } = useIntl();
    return (
        <div >
            <Form.Item
                name='title'
                rules={[
                    {
                        required: true,
                        message: formatMessage({ id: 'form_title_task_error_message', }),
                    },
                ]}
            >
                <Input
                    autoComplete='off'
                    placeholder={formatMessage({ id: 'form_title_task', })}
                    size="large"
                />
            </Form.Item>

            <VideoGenerator
                task={formTask}
                user={user}
                showAiForStudents={true}
                editOthersCourses={true}
                userIsAllowGenerateContent={true}
                {...other}
            />
       
            <Form.Item
                name='description'
                className='wysiwyg-field'
            >
                <WysiwygComponent
                    onChangeContent={(content) => {
                        form.setFieldsValue({ description: content });
                        onChangeContent();
                    }}
                    initialState={formTask?.description || ''}
                    placeholder={formatMessage({
                        id: 'form_description_task_placeholder',
                    })}
                />
            </Form.Item>
        </div>
    );
};
