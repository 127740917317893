import './scss/index.scss';
import React, { useState } from "react";
import { Button, Dropdown, Menu } from 'antd';
import { useIntl } from 'react-intl';

import { Svg, PaidComponent, useIsAllow } from '../../common';
import { ITEMS_SOURCE } from '../../constant'
import {
    AddYoutubeChannel, AddVimeoFolder, AddPdf, AddUrl,
    AddYouTubeLink, AddVimeoLink, AddMp4, AddText, AddMp3
} from './index';

export const SourceSelector = ({
    sourceList,
    setSourceList,
    channelId,
    itemsProp,
    user,
    workspace,
    isDisable,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const [inProgress, setInProgress] = useState(false);

    const handleAddSource = (data) => {
        let clone = [...sourceList];
        const sourceExists = sourceList.find(el => el.source === data.source) != null;
        if (!sourceExists) {
            clone.push(data);
            setSourceList(clone);
        }
    }

    const useIsAllowd = useIsAllow();
    const createCourses = useIsAllowd("CREATOR", workspace)
    const handleDeleteSource = (data) => {
        setSourceList(sourceList.filter(el => el.source !== data.source));
    };

    const handleChangeSource = (data, index) => {
        const sourceExists = sourceList.find(el => el.source === data.source) != null;
        setSourceList(currentSourceList => {
            const updatedSourceList = [...currentSourceList];
            if (!sourceExists) {
                // Source doesn't exist, add it
                updatedSourceList.splice(index, 1, data)
            } else {
                // Source exists, update it
                updatedSourceList.splice(index, 1)
            }
            return updatedSourceList;
        });
    }
    const items = itemsProp || ITEMS_SOURCE;
    const menu = (
        <div className="menu-wrapper">
            <Menu
            >
                {items.map((item, i) => (
                    <Menu.Item key={item.key + i} onClick={() => handleAddSource(item.sourceObject)}>
                        {item.label}
                    </Menu.Item>
                ))}
            </Menu>
        </div>
    );

    return (
        <>
            {sourceList?.length < 10 &&
                <>
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        placement="bottomLeft"
                        disabled={!createCourses || isDisable}
                    >
                        <Button type="secondary wrapper_inner-section" >
                            <span>
                                <Svg name={`:icon/add`} className='icon_16 margin_right' />
                                {formatMessage({ id: "add_your_content" })}
                            </span>
                        </Button>
                    </Dropdown>
                    {!createCourses && <PaidComponent plan={'Creator'} />}
                </>
            }
            {!!sourceList?.length && sourceList.slice(0, 15).map((item, i) => item?.key && (
                <div key={item.key + i}>
                    {item?.source ?
                        <span href={item.src} className={item.error && `error`}>
                            <Svg name={`:icon/${item.icon}`} className='menu-icon margin_right' />
                            <span className='margin_right'>
                                {item.title}
                            </span>
                            <Button type="text" size="small" disabled={!user?.id} className='wrapper_inner-section  btn_light' onClick={() => handleDeleteSource(item)} >
                                <Svg name={`:icon/cross`} className='icon_16 margin_right' />
                            </Button>
                            {item.error && <small>{item.error}</small>}
                        </span>
                        :
                        <>
                            {item.key === "yt_channel" &&
                                <AddYoutubeChannel inProgress={inProgress} setInProgress={setInProgress} index={i} sourceList={sourceList} onAddSource={handleChangeSource} {...other} />
                            }
                            {item.key === "vimeo_folder" &&
                                <AddVimeoFolder inProgress={inProgress} setInProgress={setInProgress} index={i} sourceList={sourceList} onAddSource={handleChangeSource}{...other} />
                            }
                            {item.key === "pdf" &&
                                <AddPdf inProgress={inProgress} setInProgress={setInProgress} index={i} sourceList={sourceList} onAddSource={handleChangeSource}{...other} />
                            }
                            {item.key === "url" &&
                                <AddUrl inProgress={inProgress} setInProgress={setInProgress} index={i} sourceList={sourceList} onAddSource={handleChangeSource}{...other} />
                            }
                            {item.key === "text" &&
                                <AddText inProgress={inProgress} setInProgress={setInProgress} index={i} sourceList={sourceList} onAddSource={handleChangeSource}{...other} />
                            }
                            {item.key === "youtube" &&
                                <AddYouTubeLink inProgress={inProgress} setInProgress={setInProgress} index={i} sourceList={sourceList} onAddSource={handleChangeSource}{...other} />
                            }
                            {item.key === "vimeo" &&
                                <AddVimeoLink inProgress={inProgress} setInProgress={setInProgress} index={i} sourceList={sourceList} onAddSource={handleChangeSource}{...other} />
                            }
                            {item.key === "mp3" &&
                                <AddMp3 inProgress={inProgress} setInProgress={setInProgress} index={i} sourceList={sourceList} onAddSource={handleChangeSource}{...other} />
                            }
                            {item.key === "video" &&
                                <AddMp4 inProgress={inProgress} setInProgress={setInProgress} index={i} sourceList={sourceList} onAddSource={handleChangeSource} />
                            }
                        </>
                    }
                </div>
            ))}


        </>
    );
};
