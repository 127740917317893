

export const calculateReadingHours = (text, videos, formatMessage) => {

    const wordsPerMinute = 150;
    const wordCount = text.split(' ').length;
    const videoTime = videos * 5;
    const minutes = (wordCount / wordsPerMinute + videoTime).toFixed(0);

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    let result = "";
    if (hours === 1) {
        result += `${hours} ${formatMessage({ id: 'hour' })}`;
    } else if (hours > 0) {
        result += `${hours} ${formatMessage({ id: 'hours' })}`;
    }
    if (remainingMinutes === 1) {
        result += ` ${remainingMinutes} ${formatMessage({ id: 'minute1_cuttoff' })}`;
    } else if (remainingMinutes > 1) {
        result += ` ${remainingMinutes} ${formatMessage({ id: 'minute_mupliple_cuttoff' })}`;
    }

    return result.trim();
}

export const getReadingTime = (tasks, formatMessage) => {
    let result = '';
    let textQuestionTime = 0; // Initialize a variable to keep track of additional time for TEXT questions

    // Assuming obj is the array containing your object
    tasks?.forEach(item => {
        if (item.description) {
            result += item.description + ' ';
        }

        if (item.questions) {
            item?.questions?.forEach(question => {
                result += question.question + ' ';
                if (question.answer) {  // Assuming there's an answer field in question object
                    result += question.answer + ' ';
                }
                if (question.type === "TEXT") {
                    textQuestionTime += 10; // Add 10 minutes for each TEXT question
                }
            });
        }


    });
    const reading = calculateReadingHours(result, tasks?.length + textQuestionTime / 60, formatMessage);
    return reading;
}
