import "./scss/index.scss";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from 'antd';
import { Svg, LoaderComponent, useConvertedPrice } from "../../common";
import { ParticipateComponent } from "../../event/ParticipateComponent";
import { ParticipantsListContainer } from "../../event/ParticipantsList.container";
import { InvitesFormCourseContainer } from '../../event/InvitesFormCourse.container/InvitesFormCourse.container';
import { EmailVerificationContainer } from '../../user';
import { IncludesContainer } from '../../enroll/Includes.container/Includes.container';
import { StripeSubscriptionContainer } from '../../stripe';

export const EnrollComponent = (props) => {
    const {
        event,
        paymentError,
        workspaceSubscription,
        handleParticipate,
        handleRemoveParticipant,
        user,
        isStripeConnected,
        isEditor,
        loading,
        isNotVerified,
        onJoin,
        onLogin,
        stripeAccountId,
        ...other
    } = props;
    const { formatMessage } = useIntl();
    const { getPrice } = useConvertedPrice();
    const [showModalVerification, setShowModalVerification] = useState();
    const { isConnectedAccountButton, isFree, isStripeSubscription } = useMemo(() => {
        return {
            isConnectedAccountButton: !!(isStripeConnected && event?.price),
            isFree: !event?.price,
            isStripeSubscription: !workspaceSubscription && !!event?.stripeProductId && !!event?.stripeProduct?.[0]?.prices?.length
        }
    }, [event, isStripeConnected, workspaceSubscription]);

    const handleJoin = () => {
        onJoin();
        // if (isNotVerified) {
        //     setShowModalVerification(true);
        // } else {
        //     onJoin();
        // }
    }

    const handleCheckParticipate = (data) => {
        handleParticipate(data);
        // if (isNotVerified) {
        //     setShowModalVerification(true);
        // } else {
        //     handleParticipate(data);
        // }
    };
    return (
        <div className="course-schedule ">
            <p className="wrapper_inner-section" dangerouslySetInnerHTML={{ __html: event.result, }} />
            {loading && (<LoaderComponent type="overlay" />)}

            <div>
                {isStripeSubscription && (
                    <StripeSubscriptionContainer event={event}
                        stripeAccountId={stripeAccountId}
                        onLogin={onLogin} />
                )}
                {!isStripeSubscription && (
                    <div className="event-component__participate">
                        {event.isSubscribe ? (
                            <div className="booking__promo ">
                                <div className="wrapper_inner-section">
                                    <Svg
                                        name=":icon/check"
                                        className="icon_message icon_24"
                                    />
                                    <span>
                                        {formatMessage({ id: "event_you_in", })}
                                    </span>
                                </div>
                                <div>
                                    <Button
                                        className='inline-el margin_right'
                                        onClick={() => handleRemoveParticipant(user?.id)}
                                    >
                                        {formatMessage({ id: 'unsubcribe_button' })}
                                    </Button>
                                    {/* <AddCalendarContainer event={event} /> */}
                                </div>
                            </div>
                        ) : (
                            <>
                                <small>
                                    {event.priceSubscription
                                        ? formatMessage({ id: 'subscription_title' })
                                        : (event?.price || event.priceSubscription) ?
                                            formatMessage({ id: 'purchase_title' })
                                            : null
                                    }
                                </small>
                                <div className="event_price">
                                    {(event?.price || event.priceSubscription) && !workspaceSubscription ? (
                                        <div className="inline-el">
                                            {
                                                getPrice(event.priceSubscription
                                                    ? event.priceSubscription?.forStudent
                                                    : event.price?.forStudent
                                                )
                                            }
                                        </div>
                                    ) : null

                                    }
                                </div>
                                {isConnectedAccountButton && !workspaceSubscription && (
                                    <Button type="primary"
                                        block="true"
                                        disabled={loading}
                                        onClick={handleJoin}>
                                        {formatMessage({ id: 'event_enroll_course' })}
                                    </Button>
                                )}

                                {paymentError && (
                                    <div className="error">
                                        There is something wrong with the payment system settings, please report the problem to the course creator
                                    </div>
                                )}

                                {(!isConnectedAccountButton || workspaceSubscription) && (
                                    <ParticipateComponent
                                        {...other}
                                        workspaceSubscription={workspaceSubscription}
                                        classProps=""
                                        isSubscribeForPeriod={!!event?.listTimeData?.filter((e) => e.type === "WEEKLY")?.length}
                                        onParticipate={handleCheckParticipate}
                                        event={event}
                                        block="true"
                                        isFree={isFree}
                                    />
                                )}

                                {isNotVerified && (
                                    <EmailVerificationContainer showModal={showModalVerification}
                                        setShowModal={setShowModalVerification} />
                                )}
                            </>
                        )}

                    </div>
                )}

                <IncludesContainer
                    event={event}
                    user={user}
                    {...other}
                />

                {isEditor ? (
                    // <ParticipantsListContainer
                    //     userSubscriptions={event.userSubscriptions}
                    //     handleRemoveParticipant={handleRemoveParticipant}
                    //     isEditor={isEditor}
                    //     user={user}
                    //     event={event}
                    //     {...other}
                    // />
                    <InvitesFormCourseContainer
                        {...other}
                        event={event}
                    />
                ) : event.countUserSubscriptions > 20 ?
                    <div className="booking__promo ">
                        <span className=' margin_right'> {formatMessage({ id: 'participants_list_title' })}: {event.countUserSubscriptions}</span>
                    </div>
                    : null
                }
            </div>
        </div>
    );
};
